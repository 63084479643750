<template>
  <div class="header-bg px-4 py-2">
    <div class="d-flex">
      <input
        type="text"
        class="employer-dash-search-input w-100"
        placeholder="Search for Jobs..."
      />
    </div>
  </div>
</template>
