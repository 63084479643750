<template>
  <div class="wrapper">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <Header v-if="user" :profiles="user.profiles"></Header>
    <div id="home-emp-dashboard" class="px-2">
      <div class="container-home-emp-dash mx-auto g-0 h-100">
        <Profile
          v-if="user && profileHide"
          :user="user"
          :employer-profile="employerProfile"
        ></Profile>
        <div class="row g-0 pb-4">
          <div class="col-lg-8 mt-4">
            <router-view> </router-view>
          </div>
          <div
            class="ps-5 mt-4 col-4 d-lg-block d-none"
            v-if="!employerProfile"
          >
            <div class="recent-work">
              <div
                class="d-flex justify-content-between px-xl-4 px-lg-2 px-3 pt-3 pb-3 secondary-light-bg-color border-top-left-radius border-top-right-radius"
                style="margin-bottom: 1px;">
                <div class="">
                  <h5 class="home-emp-dash-recommend-heading fw-normal mb-0">
                    Recently Worked With
                  </h5>
                </div>
                <!-- <div class="text-end">
                  <span class="text-uppercase">View all</span>
                </div> -->
              </div>
              <div class="outline-box">
                <template v-if="recentEmployers.length > 0">
                  <div
                  class="d-flex justify-content-between align-items-center px-xl-4 px-lg-2 px-4 pb-4 pt-4"
                  v-for="recentEmployer in recentEmployers"
                  >
                  <div class="d-flex align-items-center">
                    <div class="position-relative">
                      <img
                      v-if="recentEmployer.profile_pic"
                      class="profile_pic"
                      :src="recentEmployer.profile_pic.media.file_url"
                      />
                      <img
                        v-else
                        class="profile_pic"
                        src="@/assets/images/avatar.png"
                      />
                    </div>
                    <div class="ps-xl-3 ps-lg-2 ps-3">
                      <h6 class="mb-0 primary-text-color">{{ recentEmployer.full_name }}</h6>
                    </div>
                  </div>
                  <div class="text-end">
                    <router-link
                    class="text-dark recommend-posted-date-time link-offset-2 text-underline"
                    v-if="type == 'worker'"
                      :to="
                        '/dashboard/employerProfile/' +
                        recentEmployer.encrypted_user_id +
                        '/' +
                        recentEmployer.encrypted_id
                        "
                      >View Profile</router-link
                      >
                      <router-link
                      class="text-dark recommend-posted-date-time link-offset-2 text-underline"
                      v-if="type == 'employer'"
                      :to="
                        '/dashboard/workerProfile/' +
                        recentEmployer.encrypted_id +
                        '/' +
                        recentEmployer.encrypted_user_id
                      "
                      >View Profile</router-link
                    >
                  </div>
                </div>
              </template>
              <div v-else class="p-3 primary-text-color">
                <p v-if="type == 'worker'">No recent employer found.</p>
                <p v-if="type != 'worker'">No recent worker found.</p>
              </div>
            </div>
            </div>
            <!-- Home-emp-Message -->
            <div class="recent-work mt-4">
              <div
                class="d-flex justify-content-between px-xl-4 px-lg-2 px-4 pt-4 pb-3 secondary-light-bg-color border-top-left-radius border-top-right-radius"
              style="margin-bottom: 1px;">
                <div class="">
                  <h5 class="home-emp-dash-recommend-heading fw-normal mb-0">
                    Message
                  </h5>
                </div>
                <!-- <div class="text-end">
                  <span class="text-uppercase">View all</span>
                </div> -->
              </div>
              <template v-if="chatProfiles.length > 0">
                <div
                  class="d-flex justify-content-between align-items-center px-xl-4 px-lg-2 px-4 pb-4 pt-4 outline-box"
                  v-for="profile in chatProfiles"
                  @click="openChat(profile)"
                >
                  <div class="d-flex align-items-center">
                    <div class="position-relative">
                      <img
                        v-if="profile.user_profile.profile_pic"
                        class="recent-work-img"
                        :src="profile.user_profile.profile_pic.media.file_url"
                        alt=""
                      />
                      <img
                        v-else
                        class="recent-work-img"
                        src="@/assets/images/avatar.png"
                        alt=""
                      />
                    </div>
                    <div class="ps-xl-3 ps-lg-2 ps-3">
                      <h6 class="mb-0 primary-text-color">{{ profile.user_profile.full_name }}</h6>
                    </div>
                  </div>
                  <div class="text-end">
                    <span
                      class="recent-work-msg-notification fw-semibold ms-3"
                      v-if="profile.msgCount"
                      >{{ profile.msgCount }}</span
                    >
                  </div>
                </div>
              </template>
              <div v-else class="p-4 outline-box primary-text-color">No chat found.</div>
            </div>
          </div>
          <!--  -->
          <div
            class="col-md-4 ps-3 d-lg-block d-none mt-4 recent-work-nav-tabs rounded-4"
            v-if="employerProfile"
          >
            <ul
              class="nav nav-tabs header-bg rounded-top-4 fw-semibold"
              id="myTab"
              role="tablist"
            >
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="feedback-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#feedback-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="feedback-tab-pane"
                  aria-selected="true"
                >
                  Feedbacks
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="reference-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#reference-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="reference-tab-pane"
                  aria-selected="false"
                >
                  References
                </button>
              </li>
            </ul>
            <hr class="my-0" />
            <div class="tab-content py-4 px-3">
              <div
                class="tab-pane fade show active"
                id="feedback-tab-pane"
                role="tabpanel"
                aria-labelledby="feedback-tab"
                tabindex="0"
              >
                <template v-if="feedbacks.length > 0">
                  <div
                    class="card mb-2 primary-bg-color text-white rounded-5"
                    v-for="feedback in feedbacks"
                  >
                    <div class="card-body">
                      <img
                        v-if="feedback.worker && feedback.worker.profile_pic"
                        :src="feedback.worker.profile_pic.media.file_url"
                        class="profile_pic"
                      />
                      <img
                        v-else-if="
                          feedback.employer && feedback.employer.profile_pic
                        "
                        :src="feedback.employer.profile_pic.media.file_url"
                        class="profile_pic"
                      />
                      <img
                        v-else
                        src="@/assets/images/avatar.png"
                        class="profile_pic"
                      />
                      <p class="m-0">
                        <strong v-if="feedback.worker">{{
                          feedback.worker.full_name
                        }}</strong>
                        <strong v-if="feedback.employer">{{
                          feedback.employer.full_name
                        }}</strong>
                      </p>
                      <p class="m-0">{{ feedback.detail }}</p>
                      <span class="m-0 small"
                        >Posted {{ feedback.posted_at }}</span
                      >
                    </div>
                  </div>
                </template>
                <p v-else>No feedback available.</p>
              </div>
              <div
                class="tab-pane fade"
                id="reference-tab-pane"
                role="tabpanel"
                aria-labelledby="reference-tab"
                tabindex="0"
              >
                <template v-if="references.length > 0">
                  <div
                    class="card mb-2 primary-bg-color text-white rounded-5"
                    v-for="reference in references"
                  >
                    <div class="card-body">
                      <p class="m-0 mb-1">
                        <strong>{{ reference.f_name }}</strong> |
                        <small> {{ reference.designation }} </small>
                      </p>
                      <table class="table">
                        <tr>
                          <td>Phone Number:</td>
                          <td>{{ reference.phone }}</td>
                        </tr>
                        <tr>
                          <td>Email:</td>
                          <td>{{ reference.email }}</td>
                        </tr>
                        <tr>
                          <td>Address:</td>
                          <td>{{ reference.address }}</td>
                        </tr>
                        <tr>
                          <td>Relation:</td>
                          <td>{{ reference.relation }}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </template>
                <p v-else>No reference available.</p>
              </div>
            </div>
          </div>

          <!-- Chatbox -->
          <div
            class="home-emp-chatbox position-fixed ms-3 z-1 chatbox"
            :class="{ 'chat-close': !chat }"
          >
            <div
              class="chatbox-bar d-flex justify-content-between align-items-center px-4"
            >
              <h5 class="mb-0 text-light">Chat</h5>
              <img
                src="@/assets/images/Svg/dash-svg/chatbox-toogle-arrow-pic.svg"
                @click="closeChat"
              />
            </div>
            <div class="chatbox-profile-pics">
              <div class="px-4 py-2">
                <img
                  v-if="user?.profile_pic"
                  :src="user.profile_pic.file_url"
                  alt=""
                />
                <img
                  v-else
                  src="@/assets/images/avatar.png"
                  alt=""
                />
                <span class="fw-semibold fs-6 ps-3" v-if="user">{{
                  user.full_name
                }}</span>
              </div>
              <hr class="my-0" />
              <div style="overflow: auto; height: 260px" id="chat-box">
                <div
                  v-if="messages.length > 0"
                  v-for="message in messages"
                  class="px-4 mt-4"
                  :class="{ 'direction-rtl': message.user_id == upId }"
                >
                  <img
                    v-if="
                      message.user_profile && message.user_profile.profile_pic
                    "
                    :src="message.user_profile.profile_pic.media.file_url"
                    alt=""
                  />
                  <img
                    v-else
                    src="@/assets/images/avatar.png"
                    alt=""
                  />
                  <!-- <span class="pe-3">5m</span> -->
                  <span
                    class="fs-6 fw-semibold pe-2"
                    v-if="message.user_id == upId"
                    >You</span
                  >
                  <span class="fs-6 fw-semibold pe-2 ps-2" v-else>{{
                    message.user_profile.full_name
                  }}</span>
                  <p
                    class="d-flex justify-content-center align-items-center position-relative mt-2 flex-column"
                    :class="{
                      'chat-1': message.user_id == upId,
                      'chat-2': message.user_id != upId,
                    }"
                  >
                    <img
                      v-if="message.media"
                      :src="message.media.file_url"
                      style="width: 50%"
                      class="d-block"
                    />
                    <img
                      v-if="message.attachment"
                      :src="message.attachment"
                      style="width: 50%"
                      class="d-block"
                    />
                    <p>{{ message.text }}</p>
                  </p>
                </div>
              </div>
              <form @submit.prevent="sendMessage">
                <div class="d-flex justify-content-between mx-4 mt-3">
                  <input
                    class="chat-typing ps-4"
                    type="text"
                    placeholder="Type here..."
                    v-model="message"
                    required
                  />
                  <div
                    class="chat-pin d-flex justify-content-center align-items-center"
                  >
                    <img
                      src="@/assets/images/Svg/dash-svg/chatbox-attachment-pin-pic.svg"
                      alt=""
                    />
                    <input
                      type="file"
                      ref="file"
                      class="position-absolute opacity-0"
                      accept="image/jpg, image/jpeg, image/png, audio/mp3"
                      style="width: 50px; height: 50px"
                      @change="selectFile"
                    />
                  </div>
                  <div
                    class="chat-send d-flex justify-content-center align-items-center"
                  >
                    <button type="submit" class="btn">
                      <img
                        src="@/assets/images/Svg/dash-svg/chatbox-send-pic.svg"
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import Profile from "./Profile.vue";
import Pusher from "pusher-js";
import { watch } from "vue";

export default {
  components: {
    Header,
    Footer,
    Profile,
  },
  data() {
    return {
      loading: false,
      user: null,
      profileImage: null,
      skills: [],
      userId: null,
      upId: null,
      profileHide: true,
      recentEmployers: [],
      employerProfile: false,
      references: [],
      feedbacks: [],
      type: localStorage.getItem("type"),
      isUpId: false,
      chatProfiles: [],
      chat: false,
      message: null,
      messages: [],
      channelName: null,
      selectedUser: null,
      attachment: null,
      attachmentType: null,
      imagePreview: null,
      audioPreview: null,
      pusherConnection: null,
      pusherChannel: null,
    };
  },
  watch: {
    $route(to, from) {
      this.fetchData();
      this.fetchUser();
    },
  },
  mounted() {
    this.upId = localStorage.getItem("upId");
    this.userId = localStorage.getItem("uid");
    this.channelName = "EmpX." + this.upId;
    this.pusher();
    this.profileShow();
    this.fetchData();
    this.fetchUser();
    localStorage.removeItem("cnic");
    this.fetchChatProfiles();
    watch(
      () => this.messages,
      () => {
        this.scrollToBottom();
      }
    );
  },
  onUnmounted() {
    this.pusherChannel.unbind(this.channelName);
    this.pusherConnection.unsubscribe(this.channelName);
  },
  methods: {
    selectFile() {
      let file = this.$refs.file.files[0];
      if (
        file.type == "image/jpg" ||
        file.type == "image/jpeg" ||
        file.type == "image/png" ||
        file.type == "audio/mp3"
      ) {
        if (
          file.type == "image/jpg" ||
          file.type == "image/jpeg" ||
          file.type == "image/png"
        ) {
          this.attachmentType = "Image";
        } else {
          this.attachmentType = "Audio";
        }
        let reader = new FileReader();
        reader.onloadend = () => {
          this.attachment = reader.result;
        };
        reader.readAsDataURL(file);
      } else {
        file.value = null;
        this.$toast.show("File format not supported. JPG/JPEG/PNG/MP3", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        let chatBox = document.getElementById("chat-box");
        chatBox.scrollTop = chatBox.scrollHeight;
      });
    },
    sendMessage() {
      const data = {
        conversation_id: this.selectedUser.conversation_id,
        user_id: parseInt(this.upId),
        receiver_id: this.selectedUser.user_id,
        text: this.message,
        user_profile: null,
        attachment: this.attachment,
        attachment_type: this.attachmentType,
      };
      this.messages.push(data);
      this.message = null;
      this.scrollToBottom();
      this.$store
        .dispatch("chat/sendMessage", { data })
        .then((response) => {
          console.log(response);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    pusher() {
      Pusher.logToConsole = false;
      this.pusherConnection = new Pusher("52d79d5fb27a5309c721", {
        cluster: "ap1",
      });
      this.pusherChannel = this.pusherConnection.subscribe(this.channelName);
      this.pusherChannel.bind(this.channelName, (data) => {
        if (this.chat == true) {
          this.messages.push(data.data);
          this.scrollToBottom();
        } else {
          this.chatProfiles.forEach((chatProfile) => {
            if (chatProfile.id == data.data.user_profile.id) {
              chatProfile.msgCount += 1;
            }
          });
        }
      });
    },
    openChat(profile) {
      this.selectedUser = profile;
      this.loading = true;
      this.messages = [];
      if (this.chat == false) {
        this.chat = true;
      }
      const data = {
        conversation_id: profile.conversation_id,
      };
      this.$store
        .dispatch("chat/userMessages", { data })
        .then((response) => {
          this.messages = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    closeChat() {
      if (this.chat == true) {
        this.chat = false;
      }
    },
    fetchChatProfiles() {
      const data = localStorage.getItem("upId");
      this.$store
        .dispatch("chat/userProfiles", { data })
        .then((response) => {
          this.chatProfiles = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    fetchUser() {
      if (this.$route.params.id) {
        this.userId = this.$route.params.id;
        if (localStorage.getItem("type") == "worker") {
          this.type = "employer";
        } else if (localStorage.getItem("type") == "employer") {
          this.type = "worker";
        }
      } else {
        this.userId = localStorage.getItem("uid");
        this.type = localStorage.getItem("type");
      }
      this.userMe();
    },
    userMe() {
      this.loading = true;
      const id = this.userId;
      if (this.$route.query.type && this.$route.query.type == 'Company') {
        this.type = this.$route.query.type;
      }
      const type = this.type;
      this.$store
        .dispatch("userProfile/getSingle", { id, type })
        .then((response) => {
          this.loading = false;
          this.user = response.data;
          if (this.user.profile_pic) {
            localStorage.setItem(
              "profilePic",
              this.user.profile_pic.media.file_url
            );
          }
          if (this.$route.params.id) {
            this.employerProfile = true;
          } else {
            this.employerProfile = false;
            localStorage.setItem("upId", this.user.id);
            this.isUpId = true;
          }
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    fetchData() {
      if (this.$route.params.id) {
        if (this.type == "worker") {
          this.employerProfile = true;
          this.fetchEmployerFeedbackReference();
        } else if (this.type == "employer") {
          this.employerProfile = true;
          this.fetchWorkerFeedbackReference();
        }
      } else {
        this.employerProfile = false;
        if (this.type == "worker") {
          this.fetchRecentEmployers();
        } else if (this.type == "employer") {
          this.fetchRecentWorkers();
        }
      }
    },
    profileShow() {
      if (screen.width <= 992) {
        this.profileHide = false;
      }
    },
    fetchEmployerFeedbackReference() {
      const employerId = this.$route.params.empId;
      const workerId = localStorage.getItem("upId");
      this.$store
        .dispatch("userProfile/getEmployerFeedbackReference", {
          workerId,
          employerId,
        })
        .then((response) => {
          this.references = response.data.references;
          this.feedbacks = response.data.feedbacks;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    fetchWorkerFeedbackReference() {
      const workerId = this.$route.params.workerId;
      const employerId = localStorage.getItem("upId");
      this.$store
        .dispatch("userProfile/getWorkerFeedbackReference", {
          employerId,
          workerId,
        })
        .then((response) => {
          this.references = response.data.references;
          this.feedbacks = response.data.feedbacks;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    fetchRecentEmployers() {
      const id = localStorage.getItem("upId");
      this.$store
        .dispatch("userProfile/getRecentEmployers", { id })
        .then((response) => {
          this.recentEmployers = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    fetchRecentWorkers() {
      const id = localStorage.getItem("upId");
      this.$store
        .dispatch("userProfile/getRecentWorkers", { id })
        .then((response) => {
          this.recentEmployers = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style scoped>
.container {
  top: -101px;
}

.wrapper {
  background: #fff;
}

.recent-work-nav-tabs .nav-tabs .nav-link {
  background-color: transparent !important;
  border: none !important;
  padding: 20px 20px 15px 20px !important;
}

.recent-work-nav-tabs .nav-tabs .nav-link.active {
  text-decoration: underline !important;
  text-decoration-thickness: 3px !important;
  text-underline-offset: 17px !important;
}

.table {
  font-size: 12px !important;
}

.table tr td:nth-child(2) {
  color: rgba(213, 213, 213, 1) !important;
}
</style>
