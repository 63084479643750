<template>
  <div class="recent-work mt-4">
    <div
      class="d-flex justify-content-between px-xl-4 px-lg-2 px-4 pt-4 pb-3 secondary-light-bg-color border-top-left-radius border-top-right-radius"
      style="margin-bottom: 1px"
    >
      <div class="">
        <h5 class="home-emp-dash-recommend-heading fw-normal mb-0">Message</h5>
      </div>
      <!-- <div class="text-end">
                  <span class="text-uppercase">View all</span>
                </div> -->
    </div>
    <template v-if="chatProfiles.length > 0">
      <div
        class="d-flex justify-content-between align-items-center px-xl-4 px-lg-2 px-4 pb-4 pt-4 outline-box"
        v-for="profile in chatProfiles"
        @click="openChat(profile)"
      >
        <div class="d-flex align-items-center">
          <div class="position-relative">
            <img
              v-if="profile.user_profile.profile_pic"
              class="recent-work-img"
              :src="profile.user_profile.profile_pic.media.file_url"
              alt=""
            />
            <img
              v-else
              class="recent-work-img"
              src="@/assets/images/avatar.png"
              alt=""
            />
          </div>
          <div class="ps-xl-3 ps-lg-2 ps-3">
            <h6 class="mb-0 primary-text-color">
              {{ profile.user_profile.full_name }}
            </h6>
          </div>
        </div>
        <div class="text-end">
          <span
            class="recent-work-msg-notification fw-semibold ms-3"
            v-if="profile.msgCount"
            >{{ profile.msgCount }}</span
          >
        </div>
      </div>
    </template>
    <div v-else class="p-4 outline-box primary-text-color">No chat found.</div>
  </div>
  <!-- Chatbox -->
  <div
    class="home-emp-chatbox position-fixed ms-3 z-1 chatbox"
    :class="{ 'chat-close': !chat }"
  >
    <div
      class="chatbox-bar d-flex justify-content-between align-items-center px-4"
    >
      <h5 class="mb-0 text-light">Chat</h5>
      <img
        src="@/assets/images/Svg/dash-svg/chatbox-toogle-arrow-pic.svg"
        @click="closeChat"
      />
    </div>
    <div class="chatbox-profile-pics">
      <div class="px-4 py-2">
        <img
          v-if="user?.profile_pic"
          :src="user.profile_pic.file_url"
          alt=""
        />
        <img
          v-else
          src="@/assets/images/avatar.png"
          alt=""
        />
        <span class="fw-semibold fs-6 ps-3" v-if="user">{{
          user.full_name
        }}</span>
      </div>
      <hr class="my-0" />
      <div style="overflow: auto; height: 260px" id="chat-box">
        <div
          v-if="messages.length > 0"
          v-for="message in messages"
          class="px-4 mt-4"
          :class="{ 'direction-rtl': message.user_id == upId }"
        >
          <img
            v-if="
              message.user_profile && message.user_profile.profile_pic
            "
            :src="message.user_profile.profile_pic.media.file_url"
            alt=""
          />
          <img
            v-else
            src="@/assets/images/avatar.png"
            alt=""
          />
          <!-- <span class="pe-3">5m</span> -->
          <span
            class="fs-6 fw-semibold pe-2"
            v-if="message.user_id == upId"
            >You</span
          >
          <span class="fs-6 fw-semibold pe-2 ps-2" v-else>{{
            message.user_profile.full_name
          }}</span>
          <p
            class="d-flex justify-content-center align-items-center position-relative mt-2 flex-column"
            :class="{
              'chat-1': message.user_id == upId,
              'chat-2': message.user_id != upId,
            }"
          >
            <img
              v-if="message.media"
              :src="message.media.file_url"
              style="width: 50%"
              class="d-block"
            />
            <img
              v-if="message.attachment"
              :src="message.attachment"
              style="width: 50%"
              class="d-block"
            />
            <p>{{ message.text }}</p>
          </p>
        </div>
      </div>
      <form @submit.prevent="sendMessage">
        <div class="d-flex justify-content-between mx-4 mt-3">
          <input
            class="chat-typing ps-4"
            type="text"
            placeholder="Type here..."
            v-model="message"
            required
          />
          <div
            class="chat-pin d-flex justify-content-center align-items-center"
          >
            <img
              src="@/assets/images/Svg/dash-svg/chatbox-attachment-pin-pic.svg"
              alt=""
            />
            <input
              type="file"
              ref="file"
              class="position-absolute opacity-0"
              accept="image/jpg, image/jpeg, image/png, audio/mp3"
              style="width: 50px; height: 50px"
              @change="selectFile"
            />
          </div>
          <div
            class="chat-send d-flex justify-content-center align-items-center"
          >
            <button type="submit" class="btn">
              <img
                src="@/assets/images/Svg/dash-svg/chatbox-send-pic.svg"
                alt=""
              />
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { watch } from "vue";

export default {
  data() {
    return {
      pusherConnection: null,
      pusherChannel: null,
      chatProfiles: [],
      chat: false,
      message: null,
      messages: [],
      channelName: null,
      selectedUser: null,
    };
  },
  mounted() {
    this.upId = localStorage.getItem("upId");
    this.userId = localStorage.getItem("uid");
    this.channelName = "EmpX." + this.upId;
    this.pusher();
    localStorage.removeItem("cnic");
    this.fetchChatProfiles();
    watch(
      () => this.messages,
      () => {
        this.scrollToBottom();
      }
    );
  },
  onUnmounted() {
    this.pusherChannel.unbind(this.channelName);
    this.pusherConnection.unsubscribe(this.channelName);
  },
  methods: {
    selectFile() {
      let file = this.$refs.file.files[0];
      if (
        file.type == "image/jpg" ||
        file.type == "image/jpeg" ||
        file.type == "image/png" ||
        file.type == "audio/mp3"
      ) {
        if (
          file.type == "image/jpg" ||
          file.type == "image/jpeg" ||
          file.type == "image/png"
        ) {
          this.attachmentType = "Image";
        } else {
          this.attachmentType = "Audio";
        }
        let reader = new FileReader();
        reader.onloadend = () => {
          this.attachment = reader.result;
        };
        reader.readAsDataURL(file);
      } else {
        file.value = null;
        this.$toast.show("File format not supported. JPG/JPEG/PNG/MP3", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        let chatBox = document.getElementById("chat-box");
        chatBox.scrollTop = chatBox.scrollHeight;
      });
    },
    sendMessage() {
      const data = {
        conversation_id: this.selectedUser.conversation_id,
        user_id: parseInt(this.upId),
        receiver_id: this.selectedUser.user_id,
        text: this.message,
        user_profile: null,
        attachment: this.attachment,
        attachment_type: this.attachmentType,
      };
      this.messages.push(data);
      this.message = null;
      this.scrollToBottom();
      this.$store
        .dispatch("chat/sendMessage", { data })
        .then((response) => {
          console.log(response);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    pusher() {
      Pusher.logToConsole = false;
      this.pusherConnection = new Pusher("52d79d5fb27a5309c721", {
        cluster: "ap1",
      });
      this.pusherChannel = this.pusherConnection.subscribe(this.channelName);
      this.pusherChannel.bind(this.channelName, (data) => {
        if (this.chat == true) {
          this.messages.push(data.data);
          this.scrollToBottom();
        } else {
          this.chatProfiles.forEach((chatProfile) => {
            if (chatProfile.id == data.data.user_profile.id) {
              chatProfile.msgCount += 1;
            }
          });
        }
      });
    },
    openChat(profile) {
      this.selectedUser = profile;
      this.loading = true;
      this.messages = [];
      if (this.chat == false) {
        this.chat = true;
      }
      const data = {
        conversation_id: profile.conversation_id,
      };
      this.$store
        .dispatch("chat/userMessages", { data })
        .then((response) => {
          this.messages = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    closeChat() {
      if (this.chat == true) {
        this.chat = false;
      }
    },
    fetchChatProfiles() {
      const data = localStorage.getItem("upId");
      this.$store
        .dispatch("chat/userProfiles", { data })
        .then((response) => {
          this.chatProfiles = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
