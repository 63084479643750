<template>
  <div class="login-container mx-auto px-2">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <!-- Sign-up Logo -->
    <div class="login-logo row g-0 justify-content-center">
      <img src="@/assets/images/logo.png" alt="" />
    </div>
    <div class="row g-0 text-center mb-5">
      <h3 class="login-h3 mb-0">Basic Information</h3>
    </div>
    <!-- Sign-up Forms -->
    <form @submit.prevent="submit">
      <div
        class="d-flex justify-content-center align-items-center flex-column mb-5"
      >
        <div>
          <img
            :src="previewProfilePic"
            class="rounded-circle previewProfilePic"
            style="width: 150px; height: 150px"
          />
        </div>
        <div class="position-relative mt-3">
          <input
            type="file"
            id="worker-basic-info-pic-upload"
            ref="profilePic"
            class="position-absolute opacity-0"
            accept="image/png, image/jpg, image/jpeg"
            @change="profilePicSelected"
            hidden
          />
          <label
            for="worker-basic-info-pic-upload"
            type="button"
            class="upload-btn"
          >
            <img src="@/assets/images/upload.png" /> Upload Image
          </label>
        </div>
      </div>
      <div class="form-group mb-4">
        <label class="login-form-label" v-if="translations"
          >{{ translations.data["first-name"] }}
          <span class="text-danger">*</span></label
        >
        <input
          type="text"
          class="basic-info-input form-control"
          placeholder="First Name"
          v-model="userProfile.fName"
          required
          autofocus
        />
      </div>
      <div class="form-group mb-4">
        <label class="login-form-label" v-if="translations">{{
          translations.data["last-name"]
        }}</label>
        <input
          type="text"
          class="basic-info-input form-control"
          placeholder="Last Name"
          v-model="userProfile.lName"
        />
      </div>
      <div class="form-group mb-4">
        <label class="login-form-label" v-if="translations">{{
          translations.data["father-name"]
        }}</label>
        <input
          type="text"
          class="login-form-control form-control"
          placeholder="Father Name"
          v-model="userProfile.fatherName"
        />
      </div>
      <div class="form-group mb-4">
        <label class="login-form-label" v-if="translations"
          >{{ translations.data["cnic"] }}
          <span class="text-danger">*</span></label
        >
        <input
          type="text"
          class="login-form-control form-control"
          placeholder="32100012365479"
          v-model="userProfile.user.cnic"
          @keydown="numberOnly($event)"
          minlength="13"
          maxlength="13"
          required
        />
        <label class="text-danger">{{ errors?.cnic }}</label>
      </div>
      <div class="row mb-4">
        <div class="col-md-6 form-group">
          <label class="login-form-label" v-if="translations">{{
            translations.data["cnic-front-image"]
          }}</label>
          <file-input @file="cnicFrontSelected"></file-input>
          <img
            v-if="previewCnicFront"
            :src="previewCnicFront"
            class="img-thumbnail mt-3"
            style="width: 50%"
          />
        </div>
        <div class="col-md-6 form-group">
          <label class="login-form-label" v-if="translations">{{
            translations.data["cnic-back-image"]
          }}</label>
          <file-input @file="cnicBackSelected"></file-input>
          <img
            v-if="previewCnicBack"
            :src="previewCnicBack"
            class="img-thumbnail mt-3"
            style="width: 50%"
          />
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-md-6 form-group">
          <label class="login-form-label" v-if="translations">{{
            translations.data["dob"]
          }}</label>
          <input
            type="date"
            class="login-form-control form-control"
            v-model="userProfile.dob"
            max="2015-12-31"
          />
        </div>
        <div class="col-md-6 form-group">
          <label class="login-form-label" v-if="translations">{{
            translations.data["age"]
          }}</label>
          <input
            type="text"
            class="login-form-control form-control"
            placeholder="25"
            v-model="userProfile.age"
            @keydown="numberOnly($event)"
          />
        </div>
      </div>
      <div class="form-group mb-4">
        <label class="login-form-label" v-if="translations"
          >{{ translations.data["phone-number"] }}
          <span class="text-danger">*</span></label
        >
        <input
          type="text"
          class="login-form-control form-control"
          placeholder="+92 321 1234567"
          v-model="userProfile.user.phone"
          @keydown="numberOnly($event)"
          minlength="11"
          maxlength="11"
          required
        />
        <label class="text-danger">{{ errors?.phone }}</label>
      </div>
      <div class="form-group mb-4">
        <label class="login-form-label" v-if="translations"
          >{{ translations.data["email"] }}
          <span class="text-danger">*</span></label
        >
        <input
          type="email"
          class="login-form-control form-control"
          placeholder="info@gmail.com"
          v-model="userProfile.user.email"
          required
        />
        <label class="text-danger">{{ errors?.email }}</label>
      </div>
      <div class="row mb-sm-0 mb-2">
        <span class="login-form-label" v-if="translations"
          >{{ translations.data["gender"] }}
          <span class="text-danger">*</span></span
        >
      </div>
      <div
        class="row justify-content-md-start justify-content-around g-0 mb-sm-4 mb-3"
      >
        <div class="col-sm-3">
          <label class="login-form-check">
            <span
              class="login-check-span position-relative"
              v-if="translations"
              >{{ translations.data["male"] }}</span
            >
            <input
              class="login-form-check-input"
              type="radio"
              name="gender"
              value="1"
              v-model="userProfile.gender"
              id="flexCheckDefault"
              required
            />
            <span class="login-checkmark"></span>
          </label>
        </div>
        <div class="col-sm-3 mx-md-4">
          <label class="login-form-check">
            <span
              class="login-check-span position-relative"
              v-if="translations"
              >{{ translations.data["female"] }}</span
            >
            <input
              class="login-form-check-input"
              type="radio"
              name="gender"
              value="2"
              id="flexCheckDefault"
              v-model="userProfile.gender"
              required
            />
            <span class="login-checkmark"></span>
          </label>
        </div>
        <div class="col-sm-3 mx-md-4">
          <label class="login-form-check">
            <span
              class="login-check-span position-relative"
              v-if="translations"
              >{{ translations.data["other"] }}</span
            >
            <input
              class="login-form-check-input"
              type="radio"
              name="gender"
              value="2"
              id="flexCheckDefault"
              v-model="userProfile.gender"
            />
            <span class="login-checkmark"></span>
          </label>
        </div>
      </div>
      <div class="form-group mb-4">
        <label class="login-form-label" v-if="translations">{{
          translations.data["address"]
        }}</label>
        <input
          type="text"
          class="login-form-control form-control"
          placeholder="32C askari-1 Peshawar, Pakistan"
          v-model="userProfile.address"
        />
      </div>
      <div class="form-group mb-4" v-if="userProfile.type == 'worker'">
        <label class="login-form-label" v-if="translations">{{
          translations.data["locations-where-you-want"]
        }}</label>
        <multiselect :options="locations" @items="getLocations"></multiselect>
      </div>
      <div class="form-group mb-4" v-if="userProfile.type == 'worker'">
        <label class="login-form-label" v-if="translations">{{
          translations.data["skills-profession"]
        }}</label>
        <multiselect :options="skills" @items="getSkills"></multiselect>
      </div>
      <div class="form-group mb-4">
        <label class="login-form-label" v-if="translations">{{
          translations.data["qualification"]
        }}</label>
        <multiselect
          :options="qualifications"
          :maxOptions="maxQualification"
          @items="getQualification"
        ></multiselect>
      </div>
      <div class="form-group mb-4" v-if="userProfile.type == 'worker'">
        <label class="login-form-label" v-if="translations">{{
          translations.data["self-intro-video"]
        }}</label>
        <file-input :accept="video" @file="introVideoSelected"></file-input>
        <video
          class="mt-3 w-50"
          id="video-preview"
          controls
          v-show="previewIntroVideo"
        ></video>
      </div>
      <div class="form-group mb-4" v-if="userProfile.type == 'worker'">
        <label class="login-form-label" v-if="translations">{{
          translations.data["character-certificate"]
        }}</label>
        <file-input @file="policeCertificateSelected"></file-input>
        <img
          v-if="previewPoliceCertificate"
          :src="previewPoliceCertificate"
          class="img-thumbnail mt-3"
          style="width: 50%"
        />
      </div>
      <div class="form-group mb-4" v-if="userProfile.type == 'worker'">
        <label class="login-form-label" v-if="translations">{{
          translations.data["skill-certificate"]
        }}</label>
        <file-input @file="skillCertificateSelected"></file-input>
        <img
          v-if="previewSkillCertificate"
          :src="previewSkillCertificate"
          class="img-thumbnail mt-3"
          style="width: 50%"
        />
      </div>
      <div class="row g-3 mb-5" v-if="userProfile.type == 'company'">
        <div class="col-md-8">
          <label class="login-form-label" v-if="translations">{{
            translations.data["existing-company"]
          }}</label>
          <select
            class="form-control login-form-control mt-4"
            v-model="userProfile.companyId"
          >
            <option value="">Select Company</option>
            <option v-for="company in companies" :value="company.id">
              {{ company.name }}
            </option>
          </select>
        </div>
        <div class="col-md-4">
          <label class="login-form-label opacity-0" v-if="translations">{{
            translations.data["existing-company"]
          }}</label>
          <button type="submit" class="basic-info-button" @click="newCompany">
            New Company
          </button>
        </div>
      </div>
      <!-- Sign-up Button -->
      <div class="row g-3 mb-5">
        <div class="col-md-12">
          <button type="submit" class="basic-info-button">Continue</button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import Multiselect from "@/components/Multiselect.vue";
import FileInput from "@/components/FileInput.vue";
import router from "@/router";
import { isNumber, validateFileFormat } from "@/helpers/helpers.js";
import { mapGetters } from "vuex";

export default {
  components: {
    Multiselect,
    FileInput,
  },
  data() {
    return {
      loading: false,
      skills: [],
      locations: [],
      qualifications: [],
      userProfile: {
        userId: localStorage.getItem("uid"),
        fName: null,
        lName: null,
        fatherName: null,
        user: {
          cnic: null,
          phone: null,
          email: null,
        },
        dob: null,
        age: null,
        gender: null,
        address: null,
        skills: [],
        locations: [],
        qualifications: null,
        type: null,
        profilePic: null,
        companyId: "",
        isApproved: 0,
        isOwner: 0,
      },
      profilePicture: null,
      previewProfilePic: null,
      maxQualification: 1,
      cnicFront: null,
      previewCnicFront: null,
      cnicBack: null,
      previewCnicBack: null,
      introVideo: null,
      previewIntroVideo: null,
      policeCertificate: null,
      previewPoliceCertificate: null,
      skillCertificate: null,
      previewSkillCertificate: null,
      video: "video/mp4",
      companies: [],
      errors: null,
    };
  },
  computed: {
    ...mapGetters({
      translations: "getTranslation",
    }),
  },
  created() {
    this.userProfile.type = this.$route.params.type;
    this.userProfile.user.cnic = localStorage.getItem("cnic");
  },
  mounted() {
    this.fetchSkills();
    this.fetchLocations();
    this.fetchQualification();
    this.fetchCompanies();
  },
  methods: {
    newCompany() {
      this.userProfile.isApproved = 1;
      this.userProfile.isOwner = 1;
    },
    fetchCompanies() {
      this.$store
        .dispatch("company/getAll")
        .then((response) => {
          this.companies = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    introVideoSelected(file) {
      let video = document.getElementById("video-preview");
      this.previewIntroVideo = file;
      if (validateFileFormat(this.previewIntroVideo, "video")) {
        this.$refs.introVideo.value = null;
        this.previewIntroVideo = null;
        this.$toast.show("File format not supported", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          video.src = e.target.result;
        };
        reader.onloadend = () => {
          this.userProfile.introVideo = reader.result;
        };
        reader.readAsDataURL(this.previewIntroVideo);
      }
    },
    profilePicSelected(file) {
      this.profilePicture = file;
      if (validateFileFormat(this.profilePicture, "image")) {
        this.$refs.profilePic.value = null;
        this.$toast.show("File format not supported", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewProfilePic = e.target.result;
        };
        reader.onloadend = () => {
          this.userProfile.profilePic = reader.result;
        };
        reader.readAsDataURL(this.profilePicture);
      }
    },
    cnicFrontSelected(file) {
      this.cnicFront = file;
      if (validateFileFormat(this.cnicFront, "image")) {
        this.$refs.cnicfront.value = null;
        this.$toast.show("File format not supported", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewCnicFront = e.target.result;
        };
        reader.onloadend = () => {
          this.userProfile.cnicFrontImage = reader.result;
        };
        reader.readAsDataURL(this.cnicFront);
      }
    },
    cnicBackSelected(file) {
      this.cnicBack = file;
      if (validateFileFormat(this.cnicBack, "image")) {
        this.$refs.cnicback.value = null;
        this.$toast.show("File format not supported", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewCnicBack = e.target.result;
        };
        reader.onloadend = () => {
          this.userProfile.cnicBackImage = reader.result;
        };
        reader.readAsDataURL(this.cnicBack);
      }
    },
    policeCertificateSelected(file) {
      this.policeCertificate = file;
      if (validateFileFormat(this.policeCertificate, "image")) {
        this.$refs.policeCertificate.value = null;
        this.$toast.show("File format not supported", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewPoliceCertificate = e.target.result;
        };
        reader.onloadend = () => {
          this.userProfile.policeCertificate = reader.result;
        };
        reader.readAsDataURL(this.policeCertificate);
      }
    },
    skillCertificateSelected(file) {
      this.skillCertificate = file;
      if (validateFileFormat(this.skillCertificate, "image")) {
        this.$refs.skillCertificate.value = null;
        this.$toast.show("File format not supported", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewSkillCertificate = e.target.result;
        };
        reader.onloadend = () => {
          this.userProfile.skillCertificate = reader.result;
        };
        reader.readAsDataURL(this.skillCertificate);
      }
    },
    getLocations(items) {
      this.userProfile.locations = items;
    },
    getSkills(items) {
      this.userProfile.skills = items;
    },
    getQualification(items) {
      this.userProfile.qualifications = items;
    },
    numberOnly(evt) {
      isNumber(evt);
    },
    profilePicSelected() {
      this.profilePicture = this.$refs.profilePic.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.previewProfilePic = e.target.result;
      };
      reader.onloadend = () => {
        this.userProfile.profilePic = reader.result;
      };
      reader.readAsDataURL(this.profilePicture);
    },
    fetchSkills() {
      this.loading = true;
      this.$store
        .dispatch("skills/getAll")
        .then((response) => {
          this.skills = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    fetchLocations() {
      this.loading = true;
      this.$store
        .dispatch("locations/getAll")
        .then((response) => {
          this.locations = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    fetchQualification() {
      this.loading = true;
      this.$store
        .dispatch("qualifications/getAll")
        .then((response) => {
          this.qualifications = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    submit() {
      this.loading = true;
      const data = this.userProfile;
      this.$store
        .dispatch("userProfile/store", { data })
        .then((response) => {
          this.loading = false;
          if (response.success == true) {
            localStorage.setItem("upId", response.data.id);
            localStorage.removeItem("lastRoute");
            this.$toast.show("User Information has been saved successfully.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 10000,
            });
            if (this.userProfile.type == "worker") {
              router.push({
                name: "Experience",
                params: { type: this.userProfile.type },
              });
            }
            if (this.userProfile.type == "employer") {
              router.push({
                name: "Reference",
                params: { type: this.userProfile.type },
              });
            }
            if (this.userProfile.type == "company") {
              if (this.userProfile.isOwner == 1) {
                router.push({
                  name: "CompanyInformation",
                });
              } else {
                router.push({
                  path: "/dashboard/company",
                });
              }
            }
          } else {
            this.errors = response.error;
            this.$toast.show("Please review your form.", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 10000,
            });
          }
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
  },
};
</script>
