<template>
  <!-- Edit-Profile Modal -->
  <loading :active="loading" :is-full-page="true" loader="bars"></loading>
  <div
    class="modal fade"
    id="requestModal"
    tabindex="-1"
    aria-labelledby="requestModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content mt-5">
        <div>
          <h4 class="modal-title mb-3">Requests</h4>
          <div
            v-if="user.company.requests.length > 0"
            class="review-card p-3 mb-3"
            v-for="request in user.company.requests"
          >
            <div class="row align-items-center">
              <div class="col-md-6">
                <div class="dash-create-job-pic d-flex align-items-center">
                  <img
                    v-if="request.profile_pic"
                    :src="request.profile_pic.media.file_url"
                    class="profile_pic"
                  />
                  <img
                    v-else
                    src="@/assets/images/avatar.png"
                    class="profile_pic"
                  />
                  <h6 class="ms-2 text-black">
                    {{ request.full_name }}
                  </h6>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <button
                    class="col-md-5 success-btn d-flex justify-content-center align-items-center me-2"
                    @click="updateUser(request)"
                  >
                    <img
                      src="@/assets/images/hire.png"
                      alt=""
                      style="width: 15px; height: 15px"
                    />
                    <span class="ms-2">Approve</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import router from "@/router";

export default {
  props: {
    user: [],
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    updateUser(selectedUser) {
      this.loading = true;
      selectedUser.is_approved = 1;
      const data = selectedUser;
      const id = selectedUser.id;
      this.$store
        .dispatch("userProfile/updateStatus", { data, id })
        .then((response) => {
          this.loading = false;
          this.$toast.show("Request Approved.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          router.go();
        });
    },
  },
};
</script>
