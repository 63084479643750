import { createRouter, createWebHistory } from "vue-router";
import Body from "@/components/Body.vue";
import RoleSelection from "@/views/Auth/RoleSelection.vue";
import Login from "@/views/Auth/Login.vue";
import Register from "@/views/Auth/Register.vue";
import UserInformation from "@/views/BasicInformation/UserInformation.vue";
import CompanyInformation from "@/views/BasicInformation/CompanyInformation.vue";
import Reference from "@/views/BasicInformation/Reference.vue";
import EmployerDashboard from "@/views/Employer/Dashboard.vue";
import WorkerDashboard from "@/views/Worker/Dashboard.vue";
import Expereince from "@/views/BasicInformation/Expereince.vue";
import MyGigs from "@/views/Worker/MyGigs.vue";
import WorkerJobs from "@/views/Worker/MyJobs.vue";
import Feedback from "@/views/Feedback.vue";
import ContactUs from "@/views/ContactUs.vue";
import Terms from "@/views/Terms.vue";
import Privacy from "@/views/Privacy.vue";
import Support from "@/views/Support.vue";
import FAQ from "@/views/FAQ.vue";
import EmployerJobs from "@/views/Employer/MyJobs.vue";
import Recentworkmobile from "@/views/recentWorkmobile.vue";
import Messagemobile from "@/views/messageMobile.vue";
import Profilemobile from "@/views/profileMobile.vue";
import SearchResult from "@/views/SearchResult.vue";
import EmployerProfile from "@/views/Worker/EmployerProfile.vue";
import WorkerProfile from "@/views/Employer/WorkerProfile.vue";
import VerifyUser from "@/views/Auth/VerifyUser.vue";
import VerifyCode from "@/views/Auth/VerifyCode.vue";
import NewPassword from "@/views/Auth/NewPassword.vue";
import SurveyForm from "@/views/SurveyForm";

const routes = [
  {
    path: "/",
    redirect: "/role",
  },
  {
    path: "/role",
    component: RoleSelection,
    name: "RoleSelection",
    meta: {
      public: true,
    },
  },
  {
    path: "/login/:type?/:msg?",
    component: Login,
    name: "Login",
    meta: {
      public: true,
    },
  },
  {
    path: "/register/:type?",
    component: Register,
    meta: {
      public: true,
    },
  },
  {
    path: "/verify-user",
    component: VerifyUser,
    meta: {
      public: true,
    },
  },
  {
    path: "/verify-code/:msg",
    component: VerifyCode,
    meta: {
      public: true,
    },
  },
  {
    path: "/new-password",
    component: NewPassword,
    meta: {
      public: true,
    },
  },
  {
    path: "/companyInformation",
    component: CompanyInformation,
    name: "CompanyInformation",
  },
  {
    path: "/userInformation/:type?",
    component: UserInformation,
    name: "UserInformation",
  },
  {
    path: "/reference/:type?",
    component: Reference,
    name: "Reference",
  },
  {
    path: "/experience/:type?",
    component: Expereince,
    name: "Experience",
  },
  {
    path: "/dashboard/employer",
    component: Body,
    children: [
      {
        path: "/dashboard/employer",
        component: EmployerDashboard,
        name: "EmployerDashboard",
      },
    ],
  },
  {
    path: "/dashboard/company",
    component: Body,
    children: [
      {
        path: "/dashboard/company",
        component: EmployerDashboard,
        name: "CompanyDashboard",
      },
    ],
  },
  {
    path: "/dashboard/worker",
    component: Body,
    children: [
      {
        path: "/dashboard/worker",
        component: WorkerDashboard,
        name: "WorkerDashboard",
      },
    ],
  },
  {
    path: "/dashboard/employerProfile/:id/:empId",
    component: Body,
    children: [
      {
        path: "/dashboard/employerProfile/:id/:empId",
        component: EmployerProfile,
        name: "EmployerProfile",
      },
    ],
  },
  {
    path: "/dashboard/workerProfile/:id/:workerId",
    component: Body,
    children: [
      {
        path: "/dashboard/workerProfile/:id/:workerId",
        component: WorkerProfile,
        name: "WorkerProfile",
      },
    ],
  },
  {
    path: "/skill-sets",
    component: Body,
    children: [
      {
        path: "/skill-sets",
        component: MyGigs,
        name: "MyGigs",
      },
    ],
  },
  {
    path: "/myJobs",
    component: Body,
    children: [
      {
        path: "/myJobs",
        component: WorkerJobs,
        name: "WorkerJobs",
      },
    ],
  },
  {
    path: "/feedback",
    component: Body,
    children: [
      {
        path: "/feedback",
        component: Feedback,
        name: "Feedback",
      },
    ],
  },
  {
    path: "/contact-us",
    component: Body,
    children: [
      {
        path: "/contact-us",
        component: ContactUs,
        name: "ContactUs",
      },
    ],
  },
  {
    path: "/terms-and-conditions",
    component: Body,
    children: [
      {
        path: "/terms-and-conditions",
        component: Terms,
        name: "Terms",
      },
    ],
  },
  {
    path: "/privacy-policy",
    component: Body,
    children: [
      {
        path: "/privacy-policy",
        component: Privacy,
        name: "Privacy",
      },
    ],
  },
  {
    path: "/support",
    component: Body,
    children: [
      {
        path: "/support",
        component: Support,
        name: "Support",
      },
    ],
  },
  {
    path: "/faq",
    component: Body,
    children: [
      {
        path: "/faq",
        component: FAQ,
        name: "FAQ",
      },
    ],
  },
  {
    path: "/jobs/employer",
    component: Body,
    children: [
      {
        path: "/jobs/employer",
        component: EmployerJobs,
        name: "EmployerJobs",
      },
    ],
  },
  {
    path: "/recent-work-mobile",
    component: Body,
    children: [
      {
        path: "/recent-work-mobile",
        component: Recentworkmobile,
        name: "recentWorkmobile",
      },
    ],
  },
  {
    path: "/message-mobile",
    component: Body,
    children: [
      {
        path: "/message-mobile",
        component: Messagemobile,
        name: "messageMobile",
      },
    ],
  },
  {
    path: "/profile-mobile",
    component: Body,
    children: [
      {
        path: "/profile-mobile",
        component: Profilemobile,
        name: "profileMobile",
      },
    ],
  },
  {
    path: "/search/:search",
    component: Body,
    children: [
      {
        path: "/search/:search",
        component: SearchResult,
        name: "SearchResult",
      },
    ],
  },
  {
    path: "/survey-form",
    component: Body,
    children: [
      {
        path: "/survey-form/:id",
        component: SurveyForm,
        name: "SurveyForm",
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem("token");
  if (!to.meta?.public && !isAuthenticated) {
    next({ name: "RoleSelection" });
  } else {
    next();
  }
});
export default router;
