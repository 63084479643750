<template>
  <!-- Home & Job Post -->
  <a
    href="/dashboard/worker"
    class="home-emp-dash-nav-button d-flex flex-column"
  >
    <svg
      class="mx-auto"
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
    >
      <path
        d="M10.4701 1.71902C10.6107 1.57857 10.8013 1.49968 11.0001 1.49968C11.1988 1.49968 11.3895 1.57857 11.5301 1.71902L20.2201 10.409C20.2887 10.4827 20.3715 10.5418 20.4635 10.5828C20.5555 10.6238 20.6549 10.6458 20.7556 10.6476C20.8563 10.6494 20.9563 10.6309 21.0497 10.5931C21.1431 10.5554 21.2279 10.4993 21.2991 10.4281C21.3703 10.3568 21.4265 10.272 21.4642 10.1786C21.5019 10.0852 21.5204 9.9852 21.5187 9.8845C21.5169 9.78379 21.4949 9.68448 21.4539 9.59248C21.4129 9.50048 21.3538 9.41768 21.2801 9.34902L12.5911 0.659019C12.3821 0.450085 12.1341 0.284349 11.8611 0.171274C11.5881 0.0581989 11.2956 0 11.0001 0C10.7046 0 10.412 0.0581989 10.139 0.171274C9.86605 0.284349 9.61801 0.450085 9.40908 0.659019L0.719081 9.34902C0.582527 9.49054 0.50702 9.68002 0.508821 9.87667C0.510621 10.0733 0.589587 10.2614 0.728709 10.4004C0.867831 10.5394 1.05598 10.6182 1.25263 10.6198C1.44928 10.6214 1.63869 10.5457 1.78008 10.409L10.4701 1.71902Z"
        fill="white"
      />
      <path
        d="M11 3.31102L19.159 11.47C19.189 11.5 19.219 11.528 19.25 11.556V17.754C19.25 18.789 18.41 19.629 17.375 19.629H14C13.8011 19.629 13.6103 19.55 13.4697 19.4094C13.329 19.2687 13.25 19.0779 13.25 18.879V14.379C13.25 14.1801 13.171 13.9893 13.0303 13.8487C12.8897 13.708 12.6989 13.629 12.5 13.629H9.5C9.30109 13.629 9.11032 13.708 8.96967 13.8487C8.82902 13.9893 8.75 14.1801 8.75 14.379V18.879C8.75 19.0779 8.67098 19.2687 8.53033 19.4094C8.38968 19.55 8.19891 19.629 8 19.629H4.625C4.12772 19.629 3.65081 19.4315 3.29917 19.0798C2.94754 18.7282 2.75 18.2513 2.75 17.754V11.556C2.78111 11.5282 2.81146 11.4995 2.841 11.47L11 3.30902V3.31102Z"
        fill="white"
      />
    </svg>
    <span>Home</span>
  </a>
  <a href="/skill-sets" class="home-emp-dash-nav-button d-flex flex-column">
    <svg
      class="mx-auto"
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="20"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.201 2.0314C6.2308 2.00123 6.26624 1.97726 6.3053 1.96087C6.34435 1.94448 6.38624 1.93599 6.42857 1.9359H11.5714C11.6567 1.9359 11.7384 1.96989 11.7987 2.0304C11.859 2.09091 11.8929 2.17298 11.8929 2.25855V3.8034H6.10714V2.25855C6.10733 2.17337 6.14107 2.09171 6.201 2.0314ZM4.17857 3.8034V2.25855C4.17857 1.65955 4.41562 1.08507 4.83758 0.661514C5.25954 0.237954 5.83183 0 6.42857 0H11.5714C12.1682 0 12.7405 0.237954 13.1624 0.661514C13.5844 1.08507 13.8214 1.65955 13.8214 2.25855V3.8034H16.0714C16.5829 3.8034 17.0735 4.00736 17.4351 4.37041C17.7968 4.73346 18 5.22587 18 5.7393V16.0641C18 16.5775 17.7968 17.0699 17.4351 17.433C17.0735 17.796 16.5829 18 16.0714 18H1.92857C1.41708 18 0.926543 17.796 0.564865 17.433C0.203188 17.0699 0 16.5775 0 16.0641L0 5.7393C0 5.22587 0.203188 4.73346 0.564865 4.37041C0.926543 4.00736 1.41708 3.8034 1.92857 3.8034H4.17857ZM3.69643 8.42117C3.69643 7.97591 4.05643 7.61454 4.5 7.61454H13.5C13.7131 7.61454 13.9175 7.69952 14.0682 7.8508C14.2189 8.00207 14.3036 8.20724 14.3036 8.42117C14.3036 8.6351 14.2189 8.84027 14.0682 8.99154C13.9175 9.14281 13.7131 9.22779 13.5 9.22779H4.5C4.28688 9.22779 4.08249 9.14281 3.93179 8.99154C3.78109 8.84027 3.69643 8.6351 3.69643 8.42117ZM4.5 12.5756C4.39447 12.5756 4.28998 12.5965 4.19249 12.637C4.09499 12.6775 4.00641 12.737 3.93179 12.8119C3.85717 12.8868 3.79798 12.9757 3.7576 13.0736C3.71721 13.1714 3.69643 13.2763 3.69643 13.3822C3.69643 13.4882 3.71721 13.5931 3.7576 13.6909C3.79798 13.7888 3.85717 13.8777 3.93179 13.9526C4.00641 14.0275 4.09499 14.0869 4.19249 14.1275C4.28998 14.168 4.39447 14.1889 4.5 14.1889H13.5C13.6055 14.1889 13.71 14.168 13.8075 14.1275C13.905 14.0869 13.9936 14.0275 14.0682 13.9526C14.1428 13.8777 14.202 13.7888 14.2424 13.6909C14.2828 13.5931 14.3036 13.4882 14.3036 13.3822C14.3036 13.2763 14.2828 13.1714 14.2424 13.0736C14.202 12.9757 14.1428 12.8868 14.0682 12.8119C13.9936 12.737 13.905 12.6775 13.8075 12.637C13.71 12.5965 13.6055 12.5756 13.5 12.5756H4.5Z"
        fill="#7C7C7C"
      />
    </svg>
    <span>Skill-set</span>
  </a>
  <a href="/myJobs" class="home-emp-dash-nav-button d-flex flex-column">
    <svg
      class="mx-auto"
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="20"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.201 2.0314C6.2308 2.00123 6.26624 1.97726 6.3053 1.96087C6.34435 1.94448 6.38624 1.93599 6.42857 1.9359H11.5714C11.6567 1.9359 11.7384 1.96989 11.7987 2.0304C11.859 2.09091 11.8929 2.17298 11.8929 2.25855V3.8034H6.10714V2.25855C6.10733 2.17337 6.14107 2.09171 6.201 2.0314ZM4.17857 3.8034V2.25855C4.17857 1.65955 4.41562 1.08507 4.83758 0.661514C5.25954 0.237954 5.83183 0 6.42857 0H11.5714C12.1682 0 12.7405 0.237954 13.1624 0.661514C13.5844 1.08507 13.8214 1.65955 13.8214 2.25855V3.8034H16.0714C16.5829 3.8034 17.0735 4.00736 17.4351 4.37041C17.7968 4.73346 18 5.22587 18 5.7393V16.0641C18 16.5775 17.7968 17.0699 17.4351 17.433C17.0735 17.796 16.5829 18 16.0714 18H1.92857C1.41708 18 0.926543 17.796 0.564865 17.433C0.203188 17.0699 0 16.5775 0 16.0641L0 5.7393C0 5.22587 0.203188 4.73346 0.564865 4.37041C0.926543 4.00736 1.41708 3.8034 1.92857 3.8034H4.17857ZM3.69643 8.42117C3.69643 7.97591 4.05643 7.61454 4.5 7.61454H13.5C13.7131 7.61454 13.9175 7.69952 14.0682 7.8508C14.2189 8.00207 14.3036 8.20724 14.3036 8.42117C14.3036 8.6351 14.2189 8.84027 14.0682 8.99154C13.9175 9.14281 13.7131 9.22779 13.5 9.22779H4.5C4.28688 9.22779 4.08249 9.14281 3.93179 8.99154C3.78109 8.84027 3.69643 8.6351 3.69643 8.42117ZM4.5 12.5756C4.39447 12.5756 4.28998 12.5965 4.19249 12.637C4.09499 12.6775 4.00641 12.737 3.93179 12.8119C3.85717 12.8868 3.79798 12.9757 3.7576 13.0736C3.71721 13.1714 3.69643 13.2763 3.69643 13.3822C3.69643 13.4882 3.71721 13.5931 3.7576 13.6909C3.79798 13.7888 3.85717 13.8777 3.93179 13.9526C4.00641 14.0275 4.09499 14.0869 4.19249 14.1275C4.28998 14.168 4.39447 14.1889 4.5 14.1889H13.5C13.6055 14.1889 13.71 14.168 13.8075 14.1275C13.905 14.0869 13.9936 14.0275 14.0682 13.9526C14.1428 13.8777 14.202 13.7888 14.2424 13.6909C14.2828 13.5931 14.3036 13.4882 14.3036 13.3822C14.3036 13.2763 14.2828 13.1714 14.2424 13.0736C14.202 12.9757 14.1428 12.8868 14.0682 12.8119C13.9936 12.737 13.905 12.6775 13.8075 12.637C13.71 12.5965 13.6055 12.5756 13.5 12.5756H4.5Z"
        fill="#7C7C7C"
      />
    </svg>
    <span>Jobs</span>
  </a>
</template>
