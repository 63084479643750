<template>
  <div class="row g-0 employer-account-bar align-items-center">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="col-2 text-center">
      <div
        class="dash-profile-pic mb-2 justify-content-center position-relative w-auto"
      >
        <img v-if="user.profile_pic" :src="user.profile_pic.media.file_url" />
        <img
          v-else
          src="@/assets/images/avatar.png"
          alt=""
        />
        <img
          v-if="user.is_phone_number == 1 && user.is_cnic == 1"
          class="profile-verify position-absolute"
          src="@/assets/images/Svg/dash-svg/home-emp-dash-profile-verify-icon.svg"
          alt=""
          @click="showVerification"
        />
        <img
          v-else
          class="profile-verify position-absolute"
          src="@/assets/images/un-verify.png"
          alt=""
          @click="showVerification"
        />
        <div
          class="position-absolute verification-dropdown"
          v-if="verificationDropdown"
        >
          <ul>
            <li>
              <img
                v-if="user.is_phone_number == 1"
                class="profile-verify"
                src="@/assets/images/Svg/dash-svg/home-emp-dash-profile-verify-icon.svg"
                alt=""
              />
              <img
                v-else
                class="profile-verify"
                src="@/assets/images/un-verify.png"
                alt=""
              />
              <span class="ms-2">Phone Number</span>
            </li>
            <li>
              <img
                v-if="user.is_cnic == 1"
                class="profile-verify"
                src="@/assets/images/Svg/dash-svg/home-emp-dash-profile-verify-icon.svg"
                alt=""
              />
              <img
                v-else
                class="profile-verify"
                src="@/assets/images/un-verify.png"
                alt=""
              />
              <span class="ms-2">CNIC</span>
            </li>
            <li>
              <img
                v-if="user.is_references == 1"
                class="profile-verify"
                src="@/assets/images/Svg/dash-svg/home-emp-dash-profile-verify-icon.svg"
                alt=""
              />
              <img
                v-else
                class="profile-verify"
                src="@/assets/images/un-verify.png"
                alt=""
              />
              <span class="ms-2">References</span>
            </li>
            <li>
              <img
                v-if="user.is_character_certificate == 1"
                class="profile-verify"
                src="@/assets/images/Svg/dash-svg/home-emp-dash-profile-verify-icon.svg"
                alt=""
              />
              <img
                v-else
                class="profile-verify"
                src="@/assets/images/un-verify.png"
                alt=""
              />
              <span class="ms-2">Character Certificate</span>
            </li>
            <li>
              <img
                v-if="user.is_skill_certificate == 1"
                class="profile-verify"
                src="@/assets/images/Svg/dash-svg/home-emp-dash-profile-verify-icon.svg"
                alt=""
              />
              <img
                v-else
                class="profile-verify"
                src="@/assets/images/un-verify.png"
                alt=""
              />
              <span class="ms-2">Skill Certificate</span>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <h6 class="mb-0" v-if="user">{{ user.f_name }} {{ user.l_name }}</h6>
      </div>
      <div>
        <p class="fw-lighter" v-if="employerProfile">
          <span v-if="type == 'employer'">Worker</span
          ><span v-if="type == 'worker'">Employer</span>
        </p>
      </div>
    </div>
    <div class="home-emp-dash-line"></div>
    <div class="col-2 d-flex justify-content-center text-center">
      <div class="d-flex flex-column align-items-center">
        <span class="fw-semibold" v-if="user">{{ user?.user?.email }}</span>
        <span class="fw-lighter mb-2">Email</span>
        <span class="fw-semibold" v-if="user">{{ user?.user?.phone }}</span>
        <span class="fw-lighter mb-2">Phone Number</span>
        <li class="nav-item-profile-status dropdown-profile-status">
          <a
            class="nav-link text-center h-100 pt-1"
            href="javascript:void(0)"
            v-if="user"
          >
            {{ user.status }}
          </a>
          <!-- <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="#">Available</a></li>
            <li><a class="dropdown-item" href="#">Not Available</a></li>
          </ul> -->
        </li>
      </div>
    </div>
    <div class="home-emp-dash-line" v-if="type == 'worker'"></div>
    <div
      class="col-2 d-flex justify-content-center text-center"
      v-if="type == 'worker'"
    >
      <div class="d-flex flex-column">
        <span class="fw-semibold">Experience</span>
        <span class="fw-lighter mb-2" v-if="user"
          >{{ user.experience }} Years</span
        >
        <span class="fw-semibold">Skills</span>
        <span class="fw-lighter mb-2" v-if="user"
          ><span v-for="(skill, index) of user.skills"
            >{{ skill.name
            }}<span v-if="index < user.skills.length - 1">, </span></span
          ></span
        >
      </div>
    </div>
    <div class="home-emp-dash-line"></div>
    <div class="col-2 h-100 d-flex flex-column justify-content-center">
      <span class="fw-semibold text-center">Location</span>
      <span class="fw-lighter text-center" v-if="user">{{ user.address }}</span>
      <div class="mt-3 ms-3 me-3 p-2 row reward-tag" v-if="type == 'worker'">
        <span class="col-md-6">Reward</span>
        <span class="col-md-6 text-end"
          >100 <img src="@/assets/images/coins.png" alt=""
        /></span>
      </div>
    </div>
    <!-- Only for Employer -->
    <div
      class="col-2 h-100 d-flex flex-column justify-content-center"
      v-if="type == 'employer'"
    ></div>
    <!-- <div class="col-md-2" v-if="type != 'worker' || type != 'company'"></div> -->
    <div class="home-emp-dash-line" v-if="type == 'company'"></div>
    <div
      class="col-md-2 d-flex flex-column justify-content-center align-items-center"
      v-if="type == 'company'"
    >
      <div
        class="dash-profile-pic mb-2 justify-content-center position-relative w-auto"
      >
        <img
          v-if="user && user.company.media"
          :src="user.company.media.file_url"
        />
        <img
          v-else
          src="@/assets/images/avatar.png"
          alt=""
        />
        <img
          v-if="
            user.comapny &&
            user.comapny.is_phone == 1 &&
            user.company.is_reg_no == 1
          "
          class="profile-verify position-absolute"
          src="@/assets/images/Svg/dash-svg/home-emp-dash-profile-verify-icon.svg"
          style="right: 0"
          @click="showCompanyVerification"
        />
        <img
          v-else
          class="profile-verify position-absolute"
          src="@/assets/images/un-verify.png"
          style="right: 0"
          @click="showCompanyVerification"
        />
        <div
          class="position-absolute verification-dropdown"
          v-if="comVerificationDropdown"
          style="right: -180px"
        >
          <ul>
            <li>
              <img
                v-if="user.company.is_phone == 1"
                class="profile-verify"
                src="@/assets/images/Svg/dash-svg/home-emp-dash-profile-verify-icon.svg"
              />
              <img
                v-else
                class="profile-verify"
                src="@/assets/images/un-verify.png"
              />
              <span class="ms-2">Phone Number</span>
            </li>
            <li>
              <img
                v-if="user.company.is_reg_no == 1"
                class="profile-verify"
                src="@/assets/images/Svg/dash-svg/home-emp-dash-profile-verify-icon.svg"
                alt=""
              />
              <img
                v-else
                class="profile-verify"
                src="@/assets/images/un-verify.png"
                alt=""
              />
              <span class="ms-2">Registration Number</span>
            </li>
          </ul>
        </div>
      </div>
      <h6 v-if="user && user.company">{{ user.company.name }}</h6>
      <p
        v-if="user && user.is_owner == 1"
        style="text-decoration: underline; cursor: pointer"
        data-bs-toggle="modal"
        data-bs-target="#viewCompanyProfileModal"
      >
        View Company Profile
      </p>
    </div>
    <div
      class="col-3 position-relative h-100 border-left d-flex align-items-center"
      style="left: 50px"
    >
      <div class="ms-3">
        <!-- Button trigger modal -->
        <div class="d-xl-flex">
          <button
            class="home-emp-edit-profile-btn mb-xl-0 mb-2"
            data-bs-toggle="modal"
            data-bs-target="#editProfileModal"
            v-if="!employerProfile"
          >
            <span class="d-flex justify-content-center align-items-center">
              <img
                class="pe-1"
                src="@/assets/images/Svg/dash-svg/home-emp-dash-edit-profile-btn-icon.svg"
                alt=""
              />Edit Profile
            </span>
          </button>
          <button
            class="home-emp-edit-profile-btn mb-xl-0 mb-2 ms-2"
            data-bs-toggle="modal"
            data-bs-target="#requestModal"
            v-if="!employerProfile && user && user.is_owner == 1"
          >
            <span class="d-flex justify-content-center align-items-center">
              Requests
            </span>
          </button>
          <button
            class="home-emp-add-ref-btn ms-xl-2"
            data-bs-toggle="modal"
            data-bs-target="#editReferencesModal"
            v-if="type != 'company' && !employerProfile"
          >
            <span class="d-flex justify-content-center align-items-center">
              <img
                class="pe-2"
                src="@/assets/images/reference.png"
                alt=""
              />Add References
            </span>
          </button>
        </div>
        <button
          class="home-emp-add-ref-btn ms-xl-2 float-xl-end"
          data-bs-toggle="modal"
          data-bs-target="#editExperiencesModal"
          style="margin-top: 10px"
          v-if="type == 'worker' && !employerProfile"
        >
          <span class="d-flex justify-content-center align-items-center">
            <img
              class="pe-2"
              src="@/assets/images/experience.png"
              alt=""
            />Add Experience
          </span>
        </button>
      </div>
    </div>

    <!-- Edit Profile Modal -->
    <EditProfile v-if="user && !employerProfile" :user="user"></EditProfile>
    <EditReferences
      :user="user"
      v-if="
        user && (type == 'worker' || type == 'employer') && !employerProfile
      "
    ></EditReferences>
    <EditExperiences
      :user="user"
      v-if="user && type == 'worker' && !employerProfile"
    ></EditExperiences>
    <ViewcompanyProfile
      :user="user"
      v-if="user && type == 'company' && !employerProfile"
    ></ViewcompanyProfile>
    <RequestModal
      v-if="user && type == 'company' && !employerProfile"
      :user="user"
    ></RequestModal>
  </div>
</template>
<script>
import EditProfile from "./modals/EditProfile.vue";
import EditReferences from "./modals/EditReferences.vue";
import EditExperiences from "./modals/EditExperiences.vue";
import EditCompanyProfile from "./modals/EditCompanyProfile.vue";
import ViewcompanyProfile from "./modals/viewCompanyprofile.vue";
import RequestModal from "./modals/Requests.vue";

export default {
  components: {
    EditProfile,
    EditReferences,
    EditExperiences,
    EditCompanyProfile,
    ViewcompanyProfile,
    RequestModal,
  },
  props: {
    user: null,
    employerProfile: false,
  },
  data() {
    return {
      loading: false,
      userId: null,
      type: localStorage.getItem("type"),
      profileImage: null,
      verificationDropdown: false,
      comVerificationDropdown: false,
    };
  },
  methods: {
    showVerification() {
      if (this.verificationDropdown == true) {
        this.verificationDropdown = false;
      } else {
        this.verificationDropdown = true;
      }
    },
    showCompanyVerification() {
      if (this.comVerificationDropdown == true) {
        this.comVerificationDropdown = false;
      } else {
        this.comVerificationDropdown = true;
      }
    },
  },
};
</script>
