<template>
  <div class="login-container px-sm-2 px-3 mx-auto">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <!-- Login Logo -->
    <div class="login-logo row g-0 justify-content-center">
      <img src="@/assets/images/logo.png" alt="" />
    </div>
    <div class="row g-0 text-center mb-sm-0 mb-5">
      <h3 class="login-h3 mb-0">Login</h3>
      <p class="login-p mb-sm-4 mb-0">Welcome back. Please login to continue</p>
    </div>
    <!-- Login Forms -->
    <form @submit.prevent="submit" autocomplete="off">
      <span class="text-success" v-if="msg">{{ msg }}</span>
      <div class="form-group mb-sm-4 mb-3">
        <label class="login-form-label" v-if="translations">
          {{ translations.data["cnic"] }}
          <span class="text-danger">*</span></label
        >
        <span class="text-danger d-block">
          <template v-if="errors.cnic">
            {{ errors.cnic[0] }}
          </template>
          <template v-else>
            {{ errors.login }}
          </template>
        </span>
        <input
          type="text"
          class="login-form-control form-control"
          placeholder="3520112345678"
          v-model="user.cnic"
          required
          autofocus
          autocomplete="new-cnic"
          @keydown="numberOnly($event)"
          minlength="13"
          maxlength="13"
        />
      </div>
      <div class="form-group mb-sm-5 mb-4">
        <label for="InputPassword1" class="login-form-label" v-if="translations"
          >{{ translations.data["password"] }}
          <span class="text-danger">*</span></label
        >
        <span class="text-danger d-block">
          <template v-if="errors.password">
            {{ errors.password[0] }}
          </template>
        </span>
        <input
          type="password"
          class="login-form-control form-control"
          id="exampleInputPassword1"
          placeholder="Password"
          v-model="user.password"
          autocomplete="new-password"
          required
        />
      </div>
      <div class="row px-sm-4 justify-content-between align-items-center mb-4">
        <div class="col-lg-5 col-6">
          <label class="login-form-check">
            <span class="login-check-span position-relative">Remember me</span>
            <input
              class="login-form-check-input"
              type="checkbox"
              id="flexCheckDefault"
              v-model="user.remember_me"
              value="true"
            />
            <span class="login-checkmark"></span>
            <!-- <label class="login-form-check-label" for="flexCheckDefault">Remember Me</label> -->
          </label>
        </div>
        <div class="col-lg-4 col-6 d-flex justify-content-end">
          <router-link
            to="/verify-user"
            class="d-flex justify-content-end align-items-center text-white"
            style="width: 156px"
          >
            <h4 class="login-h4 mb-0">Forgot Password</h4>
            <span
              class="help-icon login-help-icon text-center d-inline-block ms-2"
              >?</span
            >
          </router-link>
        </div>
      </div>
      <!-- Login Button -->
      <div class="mb-sm-5 mb-4">
        <button type="submit" class="login-button">Login</button>
      </div>
    </form>
    <!-- OR & Lines -->
    <div class="row g-0 align-items-center">
      <div class="login-line col-5"></div>
      <div class="col-2 g-0 d-flex justify-content-center">
        <span
          class="login-OR my-0 d-flex justify-content-center align-items-center"
          >Or</span
        >
      </div>
      <div class="login-line-2 col-5 g-0"></div>
    </div>

    <!-- Login Footer -->
    <div class="mb-5 row text-center">
      <h4 class="login-h4">
        New to <span class="fw-bold">EmploymentX</span>
        <span class="help-icon text-center d-inline-block ms-1 me-1">?</span>
        <router-link
          :to="`/register/${user.role}`"
          class="text-decoration-underline fw-bold register-link"
          v-if="translations"
          >{{ translations.data["new-account"] }}</router-link
        >
      </h4>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import { mapGetters } from "vuex";
import { isNumber } from "@/helpers/helpers";

export default {
  data() {
    return {
      loading: false,
      user: {
        cnic: null,
        password: null,
        remember_me: false,
        role: null,
      },
      errors: [],
      msg: null,
    };
  },
  computed: {
    ...mapGetters({
      translations: "getTranslation",
    }),
  },
  created() {
    if (this.$route.params.msg) {
      this.msg = atob(this.$route.params.msg);
    }
    const type = localStorage.getItem("type");
    const token = localStorage.getItem("token");
    if (type && token) {
      window.location.href = "/dashboard/" + type;
    }
  },
  mounted() {
    this.user.role = this.$route.params.type;
  },
  methods: {
    numberOnly(evt) {
      isNumber(evt);
    },
    submit() {
      this.loading = true;
      this.errors = [];
      const data = toRaw(this.user);
      this.$store
        .dispatch("users/login", { data })
        .then((response) => {
          if (response.success == true) {
            localStorage.setItem("cnic", data.cnic);
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("uid", response.data.id);
            localStorage.setItem("type", this.user.role);

            if (this.user.role == "worker" && response.data.worker) {
              window.location.href = "/dashboard/worker";
            } else if (this.user.role == "employer" && response.data.employer) {
              window.location.href = "/dashboard/employer";
            } else if (this.user.role == "company" && response.data.company) {
              window.location.href = "/dashboard/company";
            } else {
              window.location.href = "/userInformation/" + this.user.role;
            }
          } else {
            this.errors = response.error;
          }
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
  },
};
</script>
