<template>
  <loading :active="loading" :is-full-page="true" loader="bars"></loading>

  <div
    class="modal fade"
    id="editExperiencesModal"
    tabindex="-1"
    aria-labelledby="editExperiencesModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" style="max-width: 600px">
      <div class="modal-content mt-5">
        <button
          type="button"
          class="modal-cross-btn ms-auto position-relative"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <img src="@/assets/images/close.png" alt="" />
        </button>
        <div class="container p-md-4 p-sm-3 p-2 text-start">
          <h1
            class="modal-title fs-5 fw-normal text-center pt-sm-0 pt-4"
            id="editExperiencesModalLabel"
          >
            Experience
          </h1>
          <p class="login-p mb-5 text-center">You can add upto 3 experiences</p>
          <form @submit.prevent="submit">
            <div class="col-12 form-group mb-4">
              <label class="login-form-label" v-if="translations">{{
                translations.data["total-experience"]
              }}</label>
              <input
                type="text"
                class="basic-info-input form-control"
                placeholder="6 Years"
                v-model="user.experience"
                @keydown="numberOnly($event)"
              />
            </div>
            <template v-for="(experience, index) in experiences">
              <div class="d-flex justify-content-end" v-if="index > 0">
                <button
                  type="button"
                  class="btn btn-sm btn-danger"
                  @click="removeExperience(index)"
                >
                  Remove
                </button>
              </div>
              <div class="col-12 form-group mb-4">
                <label class="login-form-label" v-if="translations"
                  >{{ translations.data["job-category"] }}
                  <span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  class="basic-info-input form-control"
                  placeholder="Carpenter"
                  v-model="experience.job_category"
                  required
                />
              </div>
              <div class="col-12 form-group mb-4">
                <label class="login-form-label" v-if="translations"
                  >{{ translations.data["company-name"] }}
                  <span class="text-danger">*</span></label
                >

                <input
                  type="text"
                  class="basic-info-input form-control"
                  placeholder="Company Name"
                  v-model="experience.company_name"
                  required
                />
              </div>
              <div class="col-12 form-group mb-4">
                <label class="login-form-label" v-if="translations">{{
                  translations.data["email"]
                }}</label>

                <input
                  type="email"
                  class="login-form-control form-control"
                  placeholder="info@gmail.com"
                  v-model="experience.email"
                />
              </div>
              <div class="row mb-4">
                <div class="col-sm-6 form-group mb-2">
                  <label class="login-form-label" v-if="translations">{{
                    translations.data["start-date"]
                  }}</label>

                  <input
                    type="date"
                    class="login-form-control form-control"
                    v-model="experience.start_date"
                  />
                </div>
                <div class="col-sm-6 form-group">
                  <label class="login-form-label" v-if="translations">{{
                    translations.data["end-date"]
                  }}</label>

                  <input
                    type="date"
                    class="login-form-control form-control"
                    v-model="experience.end_date"
                  />
                </div>
              </div>
              <div class="form-group mb-4">
                <label class="login-form-label" v-if="translations">{{
                  translations.data["address"]
                }}</label>

                <input
                  type="text"
                  class="login-form-control form-control"
                  placeholder="32C askari-1 Peshawar, Pakistan"
                  v-model="experience.address"
                />
              </div>
              <div class="form-group mb-4">
                <label class="login-form-label" v-if="translations">{{
                  translations.data["job-type"]
                }}</label>

                <input
                  type="text"
                  class="login-form-control form-control"
                  placeholder="Part time"
                  v-model="experience.job_type"
                />
              </div>
            </template>
            <!-- Sign-up Button -->
            <div class="row g-0 mb-5" v-if="experiences.length < 3">
              <div class="col-12">
                <button
                  type="button"
                  class="next-ref-button px-sm-0 px-2"
                  @click="addNewExperience"
                  v-if="translations"
                >
                  {{ translations.data["add-another-experience"] }}
                </button>
              </div>
            </div>
            <div class="row g-0 justify-content-between mb-5">
              <div class="col-12">
                <button type="submit" class="basic-info-button">Save</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { isNumber } from "@/helpers/helpers.js";

export default {
  props: {
    user: [],
  },
  computed: {
    ...mapGetters({
      translations: "getTranslation",
    }),
  },
  data() {
    return {
      loading: false,
      experiences: [],
      experience: {
        experience: null,
        job_category: null,
        company_name: null,
        email: null,
        start_date: null,
        end_date: null,
        address: null,
        job_type: null,
        user_id: localStorage.getItem("upId"),
      },
    };
  },
  mounted() {
    this.experiences = this.user.experiences;
  },
  methods: {
    numberOnly(evt) {
      isNumber(evt);
    },
    addNewExperience() {
      if (this.experiences.length < 3) {
        this.experience.userId = localStorage.getItem("upId");
        this.experiences.push(Object.assign({}, this.experience));
      }
    },
    removeExperience(index) {
      this.experiences.splice(index, 1);
    },
    submit() {
      this.loading = true;
      const data = {
        experiences: this.experiences,
        total_experience: this.user.experience,
      };
      const id = this.user.id;
      this.$store
        .dispatch("experience/update", { data, id })
        .then((response) => {
          this.loading = false;
          if (response.success == true) {
            this.$toast.show("Experiences has been saved successfully.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 10000,
            });
          }
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
  },
};
</script>
