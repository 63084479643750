<template>
  <loading :active="loading" :is-full-page="true" loader="bars"></loading>
  <div>
    <div>
      <div class="d-flex align-items-center home-emp-dash-create-job">
        <div class="ms-sm-5 ms-2">
          <div class="dash-create-job-pic text-center position-relative">
            <img v-if="profilePic" :src="profilePic" alt="" />
            <img v-else src="@/assets/images/avatar.png" alt="" />
          </div>
        </div>
        <button
          class="btn w-100"
          data-bs-toggle="modal"
          data-bs-target="#createJobModal"
        >
          <input
            type="text"
            class="search-create-job w-100"
            placeholder="Create a Job"
          />
        </button>
      </div>
    </div>

    <div id="recommendations">
      <!-- Recommendations -->
      <div class="row w-75">
        <h5 class="home-emp-dash-recommend-heading mt-4">Recommendations</h5>
      </div>
      <!-- Recommendations-Cards -->

      <template v-for="gig in gigs">
        <div class="row g-0 home-emp-dash-recommends mt-sm-4 mt-3">
          <div class="col-md-12 mb-3">
            <div class="dash-create-job-pic d-flex align-items-center">
              <img
                v-if="gig.worker.profile_pic"
                :src="gig.worker.profile_pic.media.file_url"
                class="profile_pic"
              />
              <img
                v-else
                src="@/assets/images/avatar.png"
                class="profile_pic"
              />
              <router-link
                :to="
                  '/dashboard/workerProfile/' +
                  gig.worker.encrypted_id +
                  '/' +
                  gig.worker.encrypted_user_id
                "
                target="_blank"
              >
                <h4 class="ms-2 mb-0 text-black">
                  {{ gig.worker.full_name }}'s Skill-set
                </h4>
              </router-link>
            </div>
          </div>
          <div
            class="container p-sm-4 p-3"
            data-bs-toggle="modal"
            data-bs-target="#showGigDetailModal"
            @click="selectGig(gig)"
          >
            <div class="row g-0">
              <div class="col-sm-6">
                <div class="row g-0 justify-content-between ms-3 mt-3">
                  <div class="col-3">
                    <h6>{{ gig.title }}</h6>
                  </div>
                </div>
                <div class="row g-0 ms-sm-3 mt-2 pe-sm-5">
                  <p class="text-ellipsis">
                    {{ gig.details }}
                  </p>
                </div>
                <span class="skill-tag" v-if="gig.skill">{{
                  gig.skill.name
                }}</span>
                <div class="row g-0 mt-4 ms-sm-3">
                  <h5
                    class="home-emp-dash-recommend-heading fw-bold col-md-6 primary-text-color"
                  >
                    {{ gig.rate }} pkr
                  </h5>
                  <span
                    class="d-flex justify-content-start align-items-center col-md-6"
                  >
                    <img
                      class="me-1"
                      src="@/assets/images/Svg/dash-svg/recommend-posted-date-icon.svg"
                      alt=""
                    />Posted
                    {{ gig.posted_at }}
                  </span>
                </div>
                <!-- <div class="recommend-posted-date-time ms-sm-3 mt-5 pt-3"></div> -->
              </div>
              <div class="col-sm-6 text-center d-flex align-items-center">
                <div class="gig-img-border-radius">
                  <div class="gig-img-border-radius">
                    <img
                      v-if="gig.cover_image"
                      :src="gig.cover_image.media.file_url"
                      alt=""
                      style="width: 100%"
                    />
                    <img
                      v-else
                      src="@/assets/images/Svg/dash-svg/recommend-carpenter-pic.svg"
                      alt=""
                      style="width: 100%"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <CreateJob></CreateJob>
    <ShowGigDetail :item="selectedGig"></ShowGigDetail>
  </div>
</template>
<script>
import CreateJob from "./CreateJob.vue";
import ShowGigDetail from "../Worker/ShowGigDetail.vue";

export default {
  components: {
    CreateJob,
    ShowGigDetail,
  },
  data() {
    return {
      user: {
        employer: [],
      },
      loading: false,
      gigs: [],
      selectedGig: [],
      profilePic: localStorage.getItem("profilePic"),
    };
  },
  mounted() {
    const lastRoute = localStorage.getItem("lastRoute");
    const type = localStorage.getItem("type");
    this.fetchGigs();
  },
  methods: {
    selectGig(gig) {
      this.selectedGig = gig;
    },
    fetchGigs() {
      this.loading = true;
      this.$store
        .dispatch("gig/recommendedGigs")
        .then((response) => {
          this.gigs = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
  },
};
</script>
<style scoped>
.wrapper {
  background: #2b2b2b;
}

header {
  background: #1f1f1f;
}
</style>
