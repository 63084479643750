<template>
  <loading :active="loading" :is-full-page="true" loader="bars"></loading>

  <div
    class="modal fade"
    id="editGigModal"
    tabindex="-1"
    aria-labelledby="editGigModalLabel"
    aria-hidden="true"
    ref="editGigModal"
  >
    <div class="modal-dialog">
      <div class="modal-content mt-5">
        <button
          type="button"
          class="modal-cross-btn ms-auto position-relative"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <img src="@/assets/images/close.png" alt="" />
        </button>
        <form @submit.prevent="submit" v-if="gig">
          <div class="container p-4">
            <h1 class="modal-title fs-5 fw-normal" id="createGigModalLabel">
              Edit Skill-set
            </h1>
            <div class="form-group mb-4 mt-4">
              <label class="login-form-label" v-if="translations"
                >{{ translations.data["job-title"] }}
                <span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="basic-info-input form-control"
                placeholder="Title"
                v-model="gig.title"
                required
              />
            </div>
            <div class="form-group mb-4">
              <label class="login-form-label" v-if="translations">{{
                translations.data["city"]
              }}</label>
              <multiselect
                :key="gig.id"
                :options="locations"
                @items="getLocations"
                :maxOptions="maxLocations"
                :select-options="gig.location"
              ></multiselect>
            </div>
            <div class="form-group mb-4">
              <label class="login-form-label" v-if="translations">{{
                translations.data["experience"]
              }}</label>

              <input
                type="text"
                class="login-form-control form-control"
                v-model="gig.experience"
                placeholder="Year"
                @keydown="numberOnly($event)"
              />
            </div>
            <div class="form-group mb-4">
              <label class="login-form-label" v-if="translations">{{
                translations.data["rate"]
              }}</label>

              <input
                type="text"
                class="login-form-control form-control"
                v-model="gig.rate"
                placeholder="PKR"
                @keydown="numberOnly($event)"
              />
            </div>
            <div class="form-group mb-4" v-if="previewVideo == null">
              <label class="login-form-label" v-if="translations">{{
                translations.data["gig-image"]
              }}</label>
              <file-input
                @file="selectPictures"
                :multiple="isMultipleImages"
              ></file-input>
              <div class="image-group row">
                <div
                  class="col-md-4 mt-3"
                  v-for="(picture, index) in gig.picturesPreview"
                >
                  <div style="position: relative">
                    <img
                      :src="picture"
                      class="img-thumbnail"
                      style="width: 100%"
                    />
                    <button
                      type="button"
                      class="btn btn-sm btn-danger"
                      style="position: absolute; top: 0; right: 0"
                      @click="removePicture(index)"
                    >
                      X
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mb-4">
              <label class="login-form-label" v-if="translations">{{
                translations.data["details"]
              }}</label>

              <input
                type="text"
                class="login-form-control form-control"
                v-model="gig.details"
                placeholder="Details"
              />
            </div>
            <div class="form-group mb-4">
              <label class="login-form-label" v-if="translations">{{
                translations.data["skills"]
              }}</label>
              <multiselect
                :key="gig.id"
                :options="skills"
                @items="getSkills"
                :maxOptions="maxSkill"
                :select-options="gig.skill"
              ></multiselect>
            </div>
            <div class="form-group mb-4">
              <label class="login-form-label" v-if="translations">{{
                translations.data["attach-audio"]
              }}</label>
              <file-input
                @file="selectAudio"
                :accept="audioFormat"
              ></file-input>
              <audio
                class="mt-3"
                controls
                id="gig-audio"
                v-show="gig.audioPreview"
                :src="gig.audioPreview"
              ></audio>
            </div>
            <div
              class="form-group mb-4"
              v-if="gig?.picturesPreview?.length == 0"
            >
              <label class="login-form-label" v-if="translations">{{
                translations.data["attach-video"]
              }}</label>
              <file-input
                @file="videoSelected"
                :accept="videoFormat"
              ></file-input>
              <video
                class="mt-3 w-50"
                id="gig-video-preview"
                controls
                v-show="gig.previewVideo"
                :src="gig.previewVideo"
              ></video>
              <button
                type="button"
                class="btn text-danger"
                @click="gig.previewVideo = null"
                v-if="gig.previewVideo"
              >
                Remove
              </button>
            </div>
            <!-- Sign-up Button -->
            <div class="row g-3 mb-5">
              <div class="col-md-6">
                <button type="submit" class="basic-info-button">Update</button>
              </div>
              <div class="col-md-6">
                <button
                  type="button"
                  class="basic-info-skip-button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { isNumber, validateFileFormat } from "@/helpers/helpers";
import Multiselect from "@/components/Multiselect.vue";
import FileInput from "@/components/FileInput.vue";
import { Modal } from "bootstrap";

export default {
  components: {
    Multiselect,
    FileInput,
  },
  computed: {
    ...mapGetters({
      translations: "getTranslation",
    }),
  },
  props: {
    selectedGig: null,
  },
  data() {
    return {
      loading: false,
      locations: [],
      skills: [],
      gig: {
        user_id: localStorage.getItem("upId"),
        title: null,
        location_id: null,
        experience: null,
        rate: null,
        images: [],
        details: null,
        skill_id: null,
        audio: null,
        video: null,
      },
      maxLocations: 1,
      maxSkill: 1,
      videoFormat: "video/mp4",
      audioFormat: "audio/mp3",
      previewVideo: null,
      audioPreview: null,
      isMultipleImages: true,
      picturesPreview: [],
    };
  },
  watch: {
    selectedGig: {
      immediate: true,
      handler(newVal) {
        this.gig = newVal;
      },
    },
  },
  mounted() {
    const modalElement = this.$refs.editGigModal;
    modalElement.addEventListener("hidden.bs.modal", this.clearForm);
    this.fetchSkills();
    this.fetchLocations();
  },
  methods: {
    clearForm() {
      this.gig = null;
    },
    submit() {
      this.loading = true;
      if (
        this.selectedGig.location_id &&
        this.selectedGig.location_id.length > 0
      ) {
        this.selectedGig.location_id = this.selectedGig.location_id[0].id;
      }
      if (this.selectedGig.skill_id && this.selectedGig.skill_id.length > 0) {
        this.selectedGig.skill_id = this.selectedGig.skill_id[0].id;
      }
      const data = this.selectedGig;
      const id = this.selectedGig.encrypted_id;
      this.$store
        .dispatch("gig/update", { id, data })
        .then(() => {
          this.loading = false;
          this.$toast.show("Skill-set has been saved.", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.$emit("saved");
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show("Failed to saved data. Try again.", {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 5000,
          });
          console.log(e);
        });
    },
    selectPictures(files) {
      const countPictures = files.length + this.selectedGig.images.length;
      if (countPictures <= 3) {
        for (var i = 0; i < files.length; i++) {
          let picture = files[i];
          let reader = new FileReader();
          reader.onload = (e) => {
            this.selectedGig.picturesPreview.push(e.target.result);
          };
          reader.onloadend = () => {
            this.selectedGig.images.push(reader.result);
          };
          reader.readAsDataURL(picture);
        }
      } else {
        this.$toast.show("You can upload max 3 pictures.", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      }
    },
    removePicture(index) {
      this.gig.picturesPreview.splice(index, 1);
      this.gig.images.splice(index, 1);
    },
    selectAudio(file) {
      this.gig.audioPreview = URL.createObjectURL(file);
      let reader = new FileReader();
      reader.onloadend = () => {
        this.gig.audio = reader.result;
      };
      reader.readAsDataURL(file);
    },
    videoSelected(file) {
      // if (validateFileFormat(this.gig.previewVideo, "video")) {
      //   this.gig.previewVideo = null;
      //   this.$toast.show("File format not supported", {
      //     theme: "outline",
      //     position: "top",
      //     type: "error",
      //     duration: 5000,
      //   });
      // } else {
      this.gig.previewVideo = URL.createObjectURL(file);
      let reader = new FileReader();
      reader.onloadend = () => {
        this.gig.video = reader.result;
      };
      reader.readAsDataURL(file);
      // }
    },
    numberOnly(evt) {
      isNumber(evt);
    },
    fetchLocations() {
      this.loading = true;
      this.$store
        .dispatch("locations/getAll")
        .then((response) => {
          this.locations = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    fetchSkills() {
      this.loading = true;
      this.$store
        .dispatch("skills/getAll")
        .then((response) => {
          this.skills = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    getLocations(items) {
      this.selectedGig.location_id = items;
    },
    getSkills(items) {
      this.selectedGig.skill_id = items;
    },
  },
};
</script>
