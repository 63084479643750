<template>
  <loading :active="loading" :is-full-page="true" loader="bars"></loading>

  <div
    class="modal fade"
    id="editReferencesModal"
    tabindex="-1"
    aria-labelledby="editReferencesModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content mt-5">
        <button
          type="button"
          class="modal-cross-btn ms-auto position-relative"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <img
            src="@/assets/images/close.png"
            alt=""
          />
        </button>
        <div class="container p-md-4 p-sm-3 p-2 text-start">
          <h1
            class="modal-title fs-5 fw-normal text-center pt-sm-0 pt-4"
            id="editReferencesModalLabel"
          >
            References
          </h1>
          <p class="login-p mb-5 text-center">You can add upto 3 References</p>
          <form @submit.prevent="submit">
            <template v-for="(reference, index) in references">
              <div class="d-flex justify-content-end" v-if="index > 0">
                <button
                  type="button"
                  class="btn btn-sm btn-danger"
                  @click="removeReference(index)"
                >
                  Remove
                </button>
              </div>
              <div class="col-12 form-group mb-4">
                <label class="login-form-label" v-if="translations">{{
                  translations.data["full-name"]
                }} <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="basic-info-input form-control"
                  placeholder="Humza Khan"
                  v-model="reference.f_name"
                  required
                />
              </div>
              <div class="form-group mb-4">
                <label class="login-form-label" v-if="translations">{{
                  translations.data["job-category-designation"]
                }}</label>
                <input
                  type="text"
                  class="login-form-control form-control"
                  placeholder="Manager"
                  v-model="reference.designation"
                />
              </div>
              <div class="form-group mb-4">
                <label class="login-form-label" v-if="translations">{{
                  translations.data["company-name"]
                }}</label>
                <input
                  type="text"
                  class="login-form-control form-control"
                  placeholder="Company Name"
                  v-model="reference.company_name"
                />
              </div>
              <div class="form-group mb-4">
                <label class="login-form-label" v-if="translations">{{
                  translations.data["email"]
                }}</label>
                <input
                  type="email"
                  class="login-form-control form-control"
                  placeholder="info@gmail.com"
                  v-model="reference.email"
                />
              </div>
              <div class="form-group mb-4">
                <label class="login-form-label" v-if="translations">{{
                  translations.data["phone-number"]
                }}</label>
                <input
                  type="text"
                  class="login-form-control form-control"
                  placeholder="+92 321 1234567"
                  max-length="11"
                  v-model="reference.phone"
                  @keydown="numberOnly($event)"
                />
              </div>
              <div class="form-group mb-4">
                <label class="login-form-label" v-if="translations">{{
                  translations.data["address"]
                }}</label>
                <input
                  type="text"
                  class="login-form-control form-control"
                  placeholder="32C askari-1 Peshawar, Pakistan"
                  v-model="reference.address"
                />
              </div>
              <div class="form-group mb-4">
                <label class="login-form-label" v-if="translations">{{
                  translations.data["relation"]
                }}</label>
                <input
                  type="text"
                  class="login-form-control form-control"
                  placeholder="Cousin"
                  v-model="reference.relation"
                />
              </div>
            </template>
            <div class="row g-0 mb-4" v-if="references.length < 3">
              <div class="col-12">
                <button
                  type="button"
                  class="next-ref-button next-ref-modal-btn px-sm-0 px-2"
                  @click="addNewReference"
                  v-if="translations"
                >
                  {{ translations.data["add-next-reference"] }}
                </button>
              </div>
            </div>
            <div class="row g-0 pb-4">
              <button type="submit" class="basic-info-button">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { isNumber } from "@/helpers/helpers";
import { mapGetters } from "vuex";

export default {
  props: {
    user: [],
  },
  computed: {
    ...mapGetters({
      translations: "getTranslation",
    }),
  },
  data() {
    return {
      references: [],
      reference: {
        f_name: null,
        phone: null,
        email: null,
        relation: null,
        designation: null,
        company_name: null,
        user_id: localStorage.getItem("upId"),
      },
      loading: false,
    };
  },
  mounted() {
    if (this.user) {
      this.references = this.user.references;
    }
  },
  methods: {
    numberOnly(evt) {
      isNumber(evt);
    },
    addNewReference() {
      if (this.references.length < 3) {
        this.references.push(Object.assign({}, this.reference));
      }
    },
    removeReference(index) {
      this.references.splice(index, 1);
    },
    submit() {
      this.loading = true;
      const data = {
        references: this.references,
      };
      const id = this.user.id;
      this.$store
        .dispatch("reference/update", { data, id })
        .then((response) => {
          this.loading = false;
          if (response.success == true) {
            this.$toast.show("References has been saved successfully.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 10000,
            });
          }
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
  },
};
</script>
