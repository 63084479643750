<template>
  <loading :active="loading" :is-full-page="true" loader="bars"></loading>
  <div
    class="modal fade"
    id="createJobModal"
    tabindex="-1"
    aria-labelledby="createJobModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content mt-5">
        <button
          type="button"
          class="modal-cross-btn ms-auto position-relative"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <img src="@/assets/images/close.png" alt="" />
        </button>
        <form @submit.prevent="submitJob('posted')">
          <div class="container p-4">
            <h1 class="modal-title fs-5 fw-normal" id="createJobModalLabel">
              Create a Job
            </h1>
            <div class="form-group mb-4 mt-4">
              <label class="login-form-label" v-if="translations"
                >{{ translations.data["job-title"] }}
                <span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="basic-info-input form-control"
                placeholder="Title"
                v-model="job.title"
                required
              />
            </div>
            <div class="form-group mb-4">
              <label class="login-form-label" v-if="translations"
                >{{ translations.data["job-category"] }}
                <span class="text-danger">*</span></label
              >
              <multiselect
                :options="jobCategories"
                @items="getCategory"
                :maxOptions="maxLocations"
              ></multiselect>
            </div>
            <div class="form-group mb-4">
              <label class="login-form-label" v-if="translations"
                >{{ translations.data["city"] }}
                <span class="text-danger">*</span></label
              >
              <multiselect
                :options="locations"
                @items="getLocations"
                :maxOptions="maxLocations"
              ></multiselect>
            </div>
            <div class="form-group mb-4">
              <label class="login-form-label" v-if="translations"
                >{{ translations.data["experience"] }}
                <span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="login-form-control form-control"
                placeholder="Year"
                v-model="job.experience"
                @keydown="numberOnly($event)"
                requried
              />
            </div>
            <div class="row">
              <label class="login-form-label" v-if="translations"
                >{{ translations.data["job-type"] }}
                <span class="text-danger">*</span></label
              >
            </div>
            <div class="row g-0 mb-4">
              <div class="col-6">
                <label class="login-form-check">
                  <span
                    class="login-check-span position-relative"
                    v-if="translations"
                    >{{ translations.data["half-time"] }}</span
                  >
                  <input
                    class="login-form-check-input"
                    type="radio"
                    name="jobType"
                    value="1"
                    id="flexCheckDefault"
                    v-model="job.type"
                    required
                  />
                  <span class="login-checkmark"></span>
                </label>
              </div>
              <div class="col-6">
                <label class="login-form-check">
                  <span
                    class="login-check-span position-relative"
                    v-if="translations"
                    >{{ translations.data["full-time"] }}</span
                  >
                  <input
                    class="login-form-check-input"
                    type="radio"
                    name="jobType"
                    value="2"
                    id="flexCheckDefault"
                    v-model="job.type"
                    required
                  />
                  <span class="login-checkmark"></span>
                </label>
              </div>
            </div>
            <div class="form-group mb-4">
              <label class="login-form-label" v-if="translations"
                >{{ translations.data["rate"] }}
                <span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="login-form-control form-control"
                placeholder="PKR/Month"
                v-model="job.salary"
                required
                @keydown="numberOnly($event)"
              />
            </div>
            <div class="row g-0 mb-4">
              <div class="col-6">
                <label class="login-form-check">
                  <span
                    class="login-check-span position-relative"
                    v-if="translations"
                    >{{ translations.data["negotiable"] }}</span
                  >
                  <input
                    class="login-form-check-input"
                    type="radio"
                    name="salaryType"
                    value="1"
                    id="flexCheckDefault"
                    v-model="job.salaryType"
                  />
                  <span class="login-checkmark"></span>
                </label>
              </div>
              <div class="col-6">
                <label class="login-form-check">
                  <span
                    class="login-check-span position-relative"
                    v-if="translations"
                    >{{ translations.data["un-negotiable"] }}</span
                  >
                  <input
                    class="login-form-check-input"
                    type="radio"
                    name="salaryType"
                    value="2"
                    id="flexCheckDefault"
                    v-model="job.salaryType"
                  />
                  <span class="login-checkmark"></span>
                </label>
              </div>
            </div>
            <div class="form-group mb-4">
              <label class="login-form-label" v-if="translations">{{
                translations.data["image"]
              }}</label>
              <file-input
                :multiple="isMultiple"
                @file="selectPictures"
              ></file-input>
              <div class="image-group row">
                <div
                  class="col-md-4 mt-3"
                  v-for="(picture, index) in picturesPreview"
                >
                  <div style="position: relative">
                    <img
                      :src="picture"
                      class="img-thumbnail"
                      style="width: 100%"
                    />
                    <button
                      type="button"
                      class="btn btn-sm btn-danger"
                      style="position: absolute; top: 0; right: 0"
                      @click="removePicture(index)"
                    >
                      X
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mb-4">
              <label class="login-form-label" v-if="translations">{{
                translations.data["attach-audio"]
              }}</label>
              <file-input :accept="audio" @file="selectAudio"></file-input>
              <audio
                class="mt-3"
                controls
                id="audio"
                v-show="audioPreview != null"
              ></audio>
            </div>
            <div class="form-group mb-4">
              <label class="login-form-label" v-if="translations"
                >{{ translations.data["details"] }}
                <span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="login-form-control form-control"
                placeholder="Details"
                v-model="job.description"
                required
              />
            </div>
            <div class="form-group mb-4">
              <label class="login-form-label" v-if="translations">{{
                translations.data["deadline"]
              }}</label>
              <input
                type="date"
                class="login-form-control form-control"
                v-model="job.deadline"
              />
            </div>
            <!-- Sign-up Button -->
            <div class="row g-3 mb-5">
              <div class="col-md-6">
                <button type="submit" class="basic-info-button">Post</button>
              </div>
              <div class="col-md-6">
                <button
                  type="button"
                  class="basic-info-skip-button"
                  @click="submitJob('draft')"
                >
                  Save as Draft
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { isNumber } from "@/helpers/helpers";
import Multiselect from "@/components/Multiselect";
import FileInput from "@/components/FileInput.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Multiselect,
    FileInput,
  },
  computed: {
    ...mapGetters({
      translations: "getTranslation",
    }),
  },
  data() {
    return {
      job: {
        jobCategoryId: null,
        locationId: null,
        employerId: localStorage.getItem("upId"),
        title: null,
        description: null,
        salary: null,
        salaryType: 1,
        type: null,
        address: null,
        pictures: [],
        deadline: null,
        audio: null,
        status: "Posted",
        experience: null,
      },
      jobCategories: [],
      picturesPreview: [],
      audioPreview: null,
      audioFile: null,
      locations: [],
      loading: false,
      audio: "audio/mp3",
      isMultiple: true,
      maxLocations: 1,
    };
  },
  mounted() {
    this.fetchLocations();
    this.fetchJobCategories();
  },
  methods: {
    getLocations(items) {
      if (items.length > 0) {
        this.job.locationId = items[0].id;
      } else {
        this.job.locationId = null;
      }
    },
    getCategory(items) {
      if (items.length > 0) {
        this.job.jobCategoryId = items[0].id;
      } else {
        this.job.jobCategoryId = null;
      }
    },
    numberOnly(evt) {
      isNumber(evt);
    },
    fetchLocations() {
      this.loading = true;
      const search = "";
      this.$store
        .dispatch("locations/getAll", { search })
        .then((response) => {
          this.locations = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    selectAudio(file) {
      this.audioPreview = file;
      let audio = document.getElementById("audio");
      let reader = new FileReader();
      reader.onload = (e) => {
        audio.src = e.target.result;
      };
      reader.onloadend = () => {
        this.job.audio = reader.result;
      };
      reader.readAsDataURL(this.audioPreview);
    },
    selectPictures(files) {
      const countPictures = files.length + this.picturesPreview.length;
      if (countPictures <= 3) {
        for (var i = 0; i < files.length; i++) {
          let picture = files[i];
          let reader = new FileReader();
          reader.onload = (e) => {
            this.picturesPreview.push(e.target.result);
          };
          reader.onloadend = () => {
            this.job.pictures.push(reader.result);
          };
          reader.readAsDataURL(picture);
        }
      } else {
        this.$toast.show("You can upload max 3 pictures.", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      }
    },
    removePicture(index) {
      this.picturesPreview.splice(index, 1);
      this.job.pictures.splice(index, 1);
    },
    fetchJobCategories() {
      this.loading = true;
      this.$store
        .dispatch("skills/getAll")
        .then((response) => {
          this.jobCategories = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    submitJob(type) {
      if (this.job.jobCategoryId == null) {
        this.$toast.show("Please select Job Category.", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else if (this.job.locationId == null) {
        this.$toast.show("Please select City.", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        this.loading = true;
        this.job.status = type;
        const data = this.job;
        this.$store
          .dispatch("jobs/store", { data })
          .then((response) => {
            console.log(response);
            this.loading = false;
            if (response.success == true) {
              this.$toast.show("Job has been saved successfully.", {
                theme: "outline",
                position: "top",
                type: "success",
                duration: 5000,
              });
              Object.keys(this.job).forEach((key) => (this.job[key] = null));
              this.job.employerId = localStorage.getItem("upId");
              this.job.salaryType = 1;
              this.job.status = "Posted";
              this.job.pictures = [];
              this.picturesPreview = [];
              this.audioPreview = null;
              this.$emit("saved");
            }
          })
          .catch((e) => {
            console.log(e);
            this.loading = false;
          });
      }
    },
  },
};
</script>
