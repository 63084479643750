<template>
  <div class="header-bg p-4 profile-mobile-main text-center rounded-4">
    <div class="d-flex justify-content-center">
      <div
        class="dash-profile-pic mb-2 justify-content-center position-relative w-auto"
      >
        <img v-if="profileImage" :src="profileImage" alt="" />
        <img v-else src="@/assets/images/avatar.png" alt="" />
        <img
          class="profile-verify position-absolute"
          src="@/assets/images/Svg/dash-svg/home-emp-dash-profile-verify-icon.svg"
          alt=""
        />
      </div>
    </div>
    <div>
      <h6 class="mb-0">{{ user?.full_name }}</h6>
    </div>
    <div>
      <p class="fw-lighter">{{ user?.type }}</p>
    </div>
    <div class="d-flex justify-content-center">
      <hr class="w-25" />
    </div>
    <div class="d-flex flex-column">
      <span class="fw-semibold text-center">Location</span>
      <span class="fw-lighter text-center">{{ user?.address }}</span>
    </div>
    <div class="d-flex justify-content-center">
      <hr class="w-25" />
    </div>
    <div class="d-flex flex-column align-items-center">
      <span class="fw-semibold">{{ user?.user?.email }}</span>
      <span class="fw-lighter mb-2">Email</span>
      <span class="fw-semibold">{{ user?.user?.phone }}</span>
      <span class="fw-lighter mb-3">Phone Number</span>
      <li class="nav-item-profile-status dropdown-profile-status">
        <a
          class="nav-link dropdown-toggle text-center h-100"
          href="#"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style="line-height: 35px"
        >
          {{ user?.status }}
        </a>
        <!-- <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="#">Available</a></li>
            <li><a class="dropdown-item" href="#">Not Available</a></li>
          </ul> -->
      </li>
    </div>
    <div class="d-flex justify-content-center">
      <hr class="w-25" />
    </div>
    <div class="d-flex flex-column">
      <span class="fw-semibold">Experience</span>
      <span class="fw-lighter mb-3">{{ user?.experience }} Years</span>
      <span class="fw-semibold">Skills</span>
      <span class="fw-lighter mb-2" v-if="user"
        ><span v-for="(skill, index) of user.skills"
          >{{ skill.name
          }}<span v-if="index < user.skills.length - 1">, </span></span
        ></span
      >
    </div>
    <div class="d-flex justify-content-center">
      <hr class="w-25" />
    </div>
    <div
      class="col-md-2 d-flex flex-column justify-content-center align-items-center"
      v-if="type == 'company'"
    >
      <div
        class="dash-profile-pic mb-2 justify-content-center position-relative w-auto"
      >
        <img
          v-if="user && user?.company?.media"
          :src="user?.company?.media?.file_url"
        />
        <img v-else src="@/assets/images/avatar.png" alt="" />
        <img
          v-if="
            user?.comapny &&
            user?.comapny?.is_phone == 1 &&
            user?.company?.is_reg_no == 1
          "
          class="profile-verify position-absolute"
          src="@/assets/images/Svg/dash-svg/home-emp-dash-profile-verify-icon.svg"
          style="right: 0"
          @click="showCompanyVerification"
        />
        <img
          v-else
          class="profile-verify position-absolute"
          src="@/assets/images/un-verify.png"
          style="right: 0"
          @click="showCompanyVerification"
        />
        <div
          class="position-absolute verification-dropdown"
          v-if="comVerificationDropdown"
          style="right: -180px"
        >
          <ul>
            <li>
              <img
                v-if="user?.company?.is_phone == 1"
                class="profile-verify"
                src="@/assets/images/Svg/dash-svg/home-emp-dash-profile-verify-icon.svg"
              />
              <img
                v-else
                class="profile-verify"
                src="@/assets/images/un-verify.png"
              />
              <span class="ms-2">Phone Number</span>
            </li>
            <li>
              <img
                v-if="user?.company?.is_reg_no == 1"
                class="profile-verify"
                src="@/assets/images/Svg/dash-svg/home-emp-dash-profile-verify-icon.svg"
                alt=""
              />
              <img
                v-else
                class="profile-verify"
                src="@/assets/images/un-verify.png"
                alt=""
              />
              <span class="ms-2">Registration Number</span>
            </li>
          </ul>
        </div>
      </div>
      <h6 v-if="user && user?.company">{{ user?.company?.name }}</h6>
      <p
        v-if="user && user?.is_owner == 1"
        style="text-decoration: underline; cursor: pointer"
        data-bs-toggle="modal"
        data-bs-target="#viewCompanyProfileModal"
      >
        View Company Profile
      </p>
    </div>
    <!-- <div class="col-md-2" v-if="type != 'worker' || type != 'company'"></div> -->
    <!-- Button trigger modal -->
    <div class="d-flex flex-column align-items-center">
      <button
        class="home-emp-edit-profile-btn mb-2"
        data-bs-toggle="modal"
        data-bs-target="#editProfileModal"
        v-if="!employerProfile"
      >
        <span class="d-flex justify-content-center align-items-center">
          <img
            class="pe-1"
            src="@/assets/images/Svg/dash-svg/home-emp-dash-edit-profile-btn-icon.svg"
            alt=""
          />Edit Profile
        </span>
      </button>
      <button
        class="home-emp-add-ref-btn mb-2"
        data-bs-toggle="modal"
        data-bs-target="#editReferencesModal"
        v-if="type != 'company' && !employerProfile"
      >
        <span class="d-flex justify-content-center align-items-center">
          <img class="pe-2" src="@/assets/images/reference.png" alt="" />Add Add
          References
        </span>
      </button>
      <button
        class="home-emp-add-ref-btn"
        data-bs-toggle="modal"
        data-bs-target="#editExperiencesModal"
        v-if="type == 'worker' && !employerProfile"
      >
        <span class="d-flex justify-content-center align-items-center">
          <img class="pe-2" src="@/assets/images/experience.png" alt="" />Add
          Add Experience
        </span>
      </button>
      <button
        class="home-emp-edit-profile-btn mb-xl-0 mb-2 ms-2"
        data-bs-toggle="modal"
        data-bs-target="#requestModal"
        v-if="!employerProfile && user && user.is_owner == 1"
      >
        <span class="d-flex justify-content-center align-items-center">
          Requests
        </span>
      </button>
    </div>
    <!-- Edit Profile Modal -->
    <EditProfile v-if="user" :user="user"></EditProfile>
    <EditReferences
      :user="user"
      v-if="user && (type == 'worker' || type == 'employer')"
    ></EditReferences>
    <EditExperiences
      :user="user"
      v-if="user && type == 'worker'"
    ></EditExperiences>
    <ViewcompanyProfile
      :user="user"
      v-if="user?.company && type == 'company' && !employerProfile"
    ></ViewcompanyProfile>
    <RequestModal
      v-if="user?.company && type == 'company' && !employerProfile"
      :user="user"
    ></RequestModal>
  </div>
</template>

<script>
import EditProfile from "@/components/modals/EditProfile.vue";
import EditReferences from "@/components/modals/EditReferences.vue";
import EditExperiences from "@/components/modals/EditExperiences.vue";
import EditCompanyProfile from "@/components/modals/EditCompanyProfile.vue";
import ViewcompanyProfile from "@/components/modals/viewCompanyprofile.vue";
import RequestModal from "@/components/modals/Requests.vue";

export default {
  components: {
    EditProfile,
    EditReferences,
    EditExperiences,
    EditCompanyProfile,
    ViewcompanyProfile,
    RequestModal,
  },
  data() {
    return {
      loading: false,
      user: null,
      userId: localStorage.getItem("uid"),
      type: localStorage.getItem("type"),
      profileImage: null,
      skills: [],
      verificationDropdown: false,
      comVerificationDropdown: false,
      employerProfile: false,
    };
  },
  mounted() {
    this.userMe();
  },
  methods: {
    userMe() {
      this.loading = true;
      const id = this.userId;
      const type = this.type;
      this.$store
        .dispatch("userProfile/getSingle", { id, type })
        .then((response) => {
          console.log(response);

          this.loading = false;
          this.user = response.data;
          this.user.user_media.forEach((mediaItem) => {
            if (mediaItem.type == "profile") {
              this.profileImage = mediaItem.media.file_url;
            }
          });
          localStorage.setItem("upId", this.user.id);
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    showVerification() {
      if (this.verificationDropdown == true) {
        this.verificationDropdown = false;
      } else {
        this.verificationDropdown = true;
      }
    },
    showCompanyVerification() {
      if (this.comVerificationDropdown == true) {
        this.comVerificationDropdown = false;
      } else {
        this.comVerificationDropdown = true;
      }
    },
  },
};
</script>
