<template>
  <div
    class="modal fade"
    id="deleteConfirmationModal"
    tabindex="-1"
    aria-labelledby="deleteConfirmationModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content mt-5">
        <div
          class="container p-3 border-bottom-left-radius border-bottom-right-radius border-top-left-radius border-top-right-radius"
        >
          <h6>Are you sure you want to delete?</h6>

          <div class="row g-0 justify-content-between mt-4">
            <div class="col-6 pe-2">
              <button
                type="submit"
                class="basic-info-button modal-save-cancel-btn"
                @click="this.$emit('delete', id)"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Yes
              </button>
            </div>
            <div class="col-6 ps-2">
              <button
                type="button"
                class="basic-info-skip-button modal-save-cancel-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: null,
  },
};
</script>
