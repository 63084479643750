<template>
  <div>
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="modal fade" id="showGigDetailModal" tabindex="-1" aria-labelledby="showGigDetailModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content mt-5">
          <button type="button" class="modal-cross-btn ms-auto z-1 position-absolute" data-bs-dismiss="modal"
            aria-label="Close">
            <img src="@/assets/images/close.png" alt="" />
          </button>
          <div>
            <div class="row g-0 home-emp-dash-recommends mt-4">
              <div class="container p-lg-4 p-3">
                <div class="row g-0 gigs-card">
                  <div class="col-md-6 mb-md-0 mb-2">
                    <div class="row g-0 justify-content-between ms-lg-3 mt-sm-3">
                      <div class="col-3">
                        <h6>{{ item.title }}</h6>
                      </div>
                    </div>
                    <div class="row g-0 ms-lg-3 mt-2 pe-lg-5 pe-2">
                      <p>
                        {{ item.details }}
                      </p>
                    </div>
                    <span class="skill-tag" v-if="item.skill">{{
      item.skill.name
    }}</span>
                    <div class="row g-0 mt-4 ms-lg-3">
                      <h5 class="home-emp-dash-recommend-heading fw-bold col-lg-6">
                        {{ item.rate }} PKR
                      </h5>
                      <span class="d-flex justify-content-start align-items-center col-lg-6 mb-lg-0 mb-3">
                        <img class="me-1" src="@/assets/images/Svg/dash-svg/recommend-posted-date-icon.svg" alt=""
                          style="width: 16px" />Posted {{ item.posted_at }}
                      </span>
                    </div>
                    <div>
                      <div class="row" v-for="media in item.gig_media">
                        <audio class="" v-if="media.type == 'Audio'" controls :src="media.media.file_url"></audio>
                      </div>
                    </div>
                    <!-- <div class="recommend-posted-date-time ms-lg-3 mt-5 pt-3"></div> -->
                  </div>
                  <div class="col-md-6 d-flex justify-content-center">
                    <div class="gig-img-border-radius">
                      <img v-if="item.cover_image" :src="item.cover_image.media.file_url" alt="" style="width: 100%" />
                      <img v-else src="@/assets/images/Svg/dash-svg/recommend-carpenter-pic.svg" alt=""
                        style="width: 100%" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { isNumber } from "@/helpers/helpers";

export default {
  props: {
    item: [],
  },
  computed: {
    ...mapGetters({
      translations: "getTranslation",
    }),
  },
  data() {
    return {
      type: localStorage.getItem("type"),
      loading: false,
      rate: null,
      comment: null,
    };
  },
  methods: {
    numberOnly(evt) {
      isNumber(evt);
    },
  },
};
</script>
