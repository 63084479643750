<template>
  <!-- Edit-Profile Modal -->
  <loading :active="loading" :is-full-page="true" loader="bars"></loading>
  <div
    class="modal fade"
    id="editCompanyProfileModal"
    tabindex="-1"
    aria-labelledby="editCompanyProfileModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content mt-5">
        <button
          type="button"
          class="modal-cross-btn ms-auto position-relative"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <img
            src="@/assets/images/close.png"
            alt=""
          />
        </button>
        <div class="view-company-prodfile-main container p-sm-4 p-3">
          <h1
            class="modal-title fs-5 fw-normal pt-sm-0 pt-4 mb-3"
            id="editProfileModalLabel"
          >
            Edit Profile
          </h1>
          <form @submit.prevent="submit">
            <div class="row justify-content-center dash-profile-pic">
              <img
                v-if="previewProfilePic"
                :src="previewProfilePic"
                class="mb-3"
              />
              <img
                v-else
                class="mb-3"
                src="@/assets/images/avatar.png"
              />
            </div>
            <div class="dropdown text-center mb-4">
              <button
                class="btn btn-secondary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span
                  class="d-flex justify-content-center align-items-center h-100"
                >
                  <img
                    class="me-1"
                    src="@/assets/images/Svg/dash-svg/home-emp-modal-photo-icon.svg"
                    alt=""
                  />Edit Photo
                </span>
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" href="javascript:void(0)">
                    <div class="position-relative">
                      <span>Upload Image</span
                      ><input
                        class="position-absolute"
                        type="file"
                        ref="file"
                        accept="image/png, image/jpg, image/jpeg"
                        @change="profilePicSelected(this.$refs.file.files[0])"
                        style="left: 0; opacity: 0"
                      />
                    </div>
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" href="javascript:void(0)"
                    >Remove Image</a
                  >
                </li>
              </ul>
            </div>
            <div class="form-group mb-4">
              <label
                for="InputEmail1"
                class="login-form-label"
                v-if="translations"
                >{{ translations.data["company-name"] }}
                <span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="login-form-control form-control"
                id="exampleInputEmail1"
                placeholder="Company Name"
                v-model="company.name"
                required
                autofocus
              />
            </div>
            <div class="form-group mb-4">
              <label
                for="InputEmail1"
                class="login-form-label"
                v-if="translations"
                >{{ translations.data["phone-number"] }}</label
              >
              <input
                type="text"
                class="login-form-control form-control"
                id="exampleInputEmail1"
                placeholder="+92 321 1234567"
                v-model="company.phone"
                @keydown="numberOnly($event)"
                minlength="11"
                maxlength="11"
              />
            </div>
            <div class="form-group mb-4">
              <label
                for="InputEmail1"
                class="login-form-label"
                v-if="translations"
                >{{ translations.data["company-website"] }}</label
              >
              <input
                type="text"
                class="login-form-control form-control"
                id="exampleInputEmail1"
                placeholder="info@gmail.com"
                v-model="company.website"
              />
            </div>
            <div class="form-group mb-4">
              <label
                for="InputPassword1"
                class="login-form-label"
                v-if="translations"
                >{{ translations.data["registration-number"] }}</label
              >
              <input
                type="text"
                class="login-form-control form-control"
                id="exampleInputPassword1"
                placeholder="Registration No"
                v-model="company.reg_no"
              />
            </div>
            <div class="form-group mb-4">
              <label
                for="InputPassword1"
                class="login-form-label"
                v-if="translations"
                >{{ translations.data["business-category"] }}</label
              >
              <multiselect
                :options="categories"
                :maxOptions="1"
                @items="getCategory"
                :select-options="[company.category]"
              ></multiselect>
            </div>
            <div class="form-group mb-4">
              <label class="login-form-label" v-if="translations">{{
                translations.data["registration-document"]
              }}</label>
              <file-input @file="documentSelected"></file-input>
              <img
                v-if="company.document"
                :src="company.document.file_url"
                class="img-thumbnail mt-3"
                style="width: 50%"
              />
            </div>
            <div class="form-group mb-4">
              <label
                for="InputPassword1"
                class="login-form-label"
                v-if="translations"
                >{{ translations.data["profile-summary"] }}</label
              >
              <input
                type="text"
                class="login-form-control form-control"
                id="exampleInputPassword1"
                placeholder="Summary"
                v-model="company.summary"
              />
            </div>
            <!-- Company Sign-up Button -->
            <div class="row g-0 mb-5">
              <button type="submit" class="login-button">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { isNumber, validateFileFormat } from "@/helpers/helpers";
import router from "@/router";
import { mapGetters } from "vuex";
import Multiselect from "@/components/Multiselect.vue";
import FileInput from "@/components/FileInput.vue";

export default {
  components: {
    Multiselect,
    FileInput,
  },
  props: {
    company: [],
  },
  data() {
    return {
      loading: false,
      profilePicture: null,
      previewProfilePic: null,
      previewDocument: null,
      regDocument: null,
      categories: [],
    };
  },
  computed: {
    ...mapGetters({
      translations: "getTranslation",
    }),
  },
  mounted() {
    this.fetchCategories();
  },
  methods: {
    getCategory(items) {
      this.company.category_id = items[0].id;
    },
    fetchCategories() {
      this.loading = true;
      this.$store
        .dispatch("company/getCategories")
        .then((response) => {
          this.categories = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    documentSelected(file) {
      this.regDocument = file;
      if (validateFileFormat(this.regDocument, "image")) {
        this.$toast.show("File format not supported", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      } else {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewDocument = e.target.result;
        };
        reader.onloadend = () => {
          this.company.document = reader.result;
        };
        reader.readAsDataURL(this.regDocument);
      }
    },
    profilePicSelected() {
      this.profilePicture = this.$refs.profilePic.files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        this.previewProfilePic = e.target.result;
      };
      reader.onloadend = () => {
        this.company.profileImage = reader.result;
      };
      reader.readAsDataURL(this.profilePicture);
    },
    numberOnly(evt) {
      isNumber(evt);
    },
    submit() {
      this.loading = true;
      const data = this.company;
      this.$store
        .dispatch("company/store", { data })
        .then((response) => {
          this.loading = false;
          if (response.success == true) {
            this.$toast.show(
              "Company Information has been saved successfully.",
              {
                theme: "outline",
                position: "top",
                type: "success",
                duration: 10000,
              }
            );
            router.push({
              path: "/dashboard/company",
            });
          }
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
  },
};
</script>
