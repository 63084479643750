<template>
  <div>
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>

    <!-- Survey form -->
    <SurveyForm
      v-if="form"
      :surveyForm="form"
      @skipped="fetchForm"
    ></SurveyForm>

    <div>
      <div class="d-flex align-items-center home-emp-dash-create-job">
        <div class="ms-3">
          <div class="dash-create-job-pic text-center position-relative">
            <img v-if="profilePic" :src="profilePic" alt="" />
            <img v-else src="@/assets/images/avatar.png" alt="" />
          </div>
        </div>
        <button
          class="btn w-100"
          data-bs-toggle="modal"
          data-bs-target="#createGigModal"
        >
          <input
            type="text"
            class="search-create-job w-100"
            placeholder="Create a Skill-set"
          />
        </button>
      </div>
    </div>

    <!-- Recommendations-Cards -->
    <div id="posted-jobs">
      <div class="row w-75">
        <h5
          class="home-emp-dash-recommend-heading mb-sm-3 mb-0 fw-normal ms-4 mt-4"
        >
          Recommendations
        </h5>
      </div>
      <div class="row g-0 home-emp-dash-recommends p-sm-3 p-2">
        <div
          class="container mb-3 p-sm-4 p-3"
          v-if="activeJobs.length > 0"
          v-for="job in activeJobs"
        >
          <div class="row">
            <div class="col-md-12 mb-3">
              <div class="dash-create-job-pic d-flex align-items-center">
                <img
                  v-if="job.employer.profile_pic"
                  :src="job.employer.profile_pic.media.file_url"
                  class="profile_pic"
                />
                <img
                  v-else
                  src="@/assets/images/avatar.png"
                  class="profile_pic"
                />
                <router-link
                  :to="
                    '/dashboard/employerProfile/' +
                    job.employer.encrypted_user_id +
                    '/' +
                    job.employer.encrypted_id +
                    '?type=' +
                    job.employer.type
                  "
                  target="_blank"
                >
                  <h4 class="ms-2 mb-0 primary-text-color">
                    {{ job.employer.full_name }}'s Job Post
                  </h4>
                </router-link>
              </div>
            </div>
            <div
              class="col-sm-3 mb-sm-0 mb-2"
              data-bs-toggle="modal"
              data-bs-target="#showJobDetailModal"
              @click="selectItem(job)"
            >
              <h6 class="mb-0 ps-2">{{ job.title }}</h6>
            </div>
            <div
              class="col-sm-9 d-flex ms-auto justify-content-end align-items-center"
            >
              <div
                class="recommend-location d-flex justify-content-center align-items-center"
              >
                <img
                  class="pe-1"
                  src="@/assets/images/Svg/dash-svg/recommend-location-icon.svg"
                  alt=""
                />{{ job.location.name }}
              </div>
              <div
                class="recommend-location mx-2 d-flex justify-content-center align-items-center"
              >
                <img
                  class="pe-2"
                  src="@/assets/images/Svg/dash-svg/recommend-experience-icon.svg"
                  alt=""
                />{{ job.experience }} years exp.
              </div>
              <div
                class="recommend-location d-flex justify-content-center align-items-center"
              >
                <img
                  class="pe-2"
                  src="@/assets/images/Svg/dash-svg/recommend-fulltime-icon.svg"
                  alt=""
                />{{ job.type }}
              </div>
            </div>
          </div>
          <div
            class="row mt-3"
            data-bs-toggle="modal"
            data-bs-target="#showJobDetailModal"
            @click="selectItem(job)"
          >
            <span class="text-ellipsis">{{ job.description }}</span>
          </div>
          <div
            class="d-sm-flex justify-content-sm-start align-items-center mt-3 dash-recommendation"
          >
            <h5
              class="home-emp-dash-recommend-heading mb-0 d-flex align-items-center fw-bold mb-sm-0 mb-2 primary-text-color"
            >
              PKR {{ job.salary }}/mo
            </h5>
            <div class="recommend-posted-date-time ps-sm-5">
              <span class="d-flex justify-content-end align-items-center">
                <img
                  class="me-1"
                  src="@/assets/images/Svg/dash-svg/recommend-posted-date-icon.svg"
                  alt=""
                />Posted {{ job.posted_at }}
              </span>
            </div>
          </div>
          <div
            class="row mt-3 mb-sm-3"
            data-bs-toggle="modal"
            data-bs-target="#showJobDetailModal"
            @click="selectItem(job)"
          >
            <div
              class="col-md-3 col-sm-4 col-6 me-2"
              v-for="media in job.job_media"
            >
              <img
                v-if="media.media.type == 'Image'"
                :src="media.media.file_url"
                style="width: 100%; border-radius: 16px"
              />
            </div>
          </div>
        </div>
        <div v-else>
          <p class="text-white">No Jobs Found...</p>
        </div>
      </div>
    </div>

    <ShowJobDetail :item="selectedItem"></ShowJobDetail>
    <CreateGig></CreateGig>
  </div>
</template>
<script>
import ShowJobDetail from "./ShowJobDetail.vue";
import CreateGig from "./CreateGig.vue";
import SurveyForm from "@/components/SurveyForm";

export default {
  components: {
    ShowJobDetail,
    CreateGig,
    SurveyForm,
  },
  data() {
    return {
      loading: false,
      activeJobs: [],
      selectedItem: [],
      profilePic: null,
      form: null,
    };
  },
  mounted() {
    if (localStorage.getItem("profilePic")) {
      this.profilePic = localStorage.getItem("profilePic");
    }
    this.fetchJobs();
    this.fetchForm();
  },
  methods: {
    selectItem(item) {
      this.selectedItem = item;
    },
    fetchForm() {
      const id = localStorage.getItem("upId");
      this.$store
        .dispatch("form/get", { id })
        .then((response) => {
          this.form = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    fetchJobs() {
      this.loading = true;
      const id = localStorage.getItem("upId");
      this.$store
        .dispatch("jobs/getAll", { id })
        .then((response) => {
          this.loading = false;
          this.activeJobs = response.data;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
  },
};
</script>
<style scoped>
.wrapper {
  background: #2b2b2b;
}

header {
  background: #1f1f1f;
}
</style>
