<template>
  <div class="recent-work">
    <div
      class="d-flex justify-content-between px-xl-4 px-lg-2 px-3 pt-3 pb-3 secondary-light-bg-color border-top-left-radius border-top-right-radius"
      style="margin-bottom: 1px"
    >
      <div class="">
        <h5 class="home-emp-dash-recommend-heading fw-normal mb-0">
          Recently Worked With
        </h5>
      </div>
      <!-- <div class="text-end">
                  <span class="text-uppercase">View all</span>
                </div> -->
    </div>
    <div class="outline-box">
      <template v-if="recentEmployers.length > 0">
        <div
          class="d-flex justify-content-between align-items-center px-xl-4 px-lg-2 px-4 pb-4 pt-4"
          v-for="recentEmployer in recentEmployers"
        >
          <div class="d-flex align-items-center">
            <div class="position-relative">
              <img
                v-if="recentEmployer.profile_pic"
                class="profile_pic"
                :src="recentEmployer.profile_pic.media.file_url"
              />
              <img
                v-else
                class="profile_pic"
                src="@/assets/images/avatar.png"
              />
            </div>
            <div class="ps-xl-3 ps-lg-2 ps-3">
              <h6 class="mb-0 primary-text-color">
                {{ recentEmployer.full_name }}
              </h6>
            </div>
          </div>
          <div class="text-end">
            <router-link
              class="text-dark recommend-posted-date-time link-offset-2 text-underline"
              v-if="type == 'worker'"
              :to="
                '/dashboard/employerProfile/' +
                recentEmployer.user_id +
                '/' +
                recentEmployer.id
              "
              >View Profile</router-link
            >
            <router-link
              class="text-dark recommend-posted-date-time link-offset-2 text-underline"
              v-if="type == 'employer'"
              :to="
                '/dashboard/workerProfile/' +
                recentEmployer.id +
                '/' +
                recentEmployer.user_id
              "
              >View Profile</router-link
            >
          </div>
        </div>
      </template>
      <div v-else class="p-3 primary-text-color">
        <p v-if="type == 'worker'">No recent employer found.</p>
        <p v-if="type != 'worker'">No recent worker found.</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      recentEmployers: [],
      type: localStorage.getItem("type"),
    };
  },
  mounted() {
    if (this.type == "worker") {
      this.fetchRecentEmployers();
    } else if (this.type == "employer") {
      this.fetchRecentWorkers();
    }
  },
  methods: {
    fetchRecentEmployers() {
      const id = localStorage.getItem("upId");
      this.$store
        .dispatch("userProfile/getRecentEmployers", { id })
        .then((response) => {
          this.recentEmployers = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    fetchRecentWorkers() {
      const id = localStorage.getItem("upId");
      this.$store
        .dispatch("userProfile/getRecentWorkers", { id })
        .then((response) => {
          this.recentEmployers = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
